import { GetFrequentlyUsedInformationOutput } from '@tallkingconnect/gateway';
import { useQuery } from '@tanstack/react-query';
import ms from 'ms';
import { emptyArray } from 'src/constants';
import { useGateway } from 'src/providers/gateway';
import { createSelectOptions } from './helpers';

function selectCommonData(data: GetFrequentlyUsedInformationOutput) {
  // NB: need to be very explicit here because TypeScript is is a bitch sometimes
  return {
    careSectors: createSelectOptions(data.careSectors ?? (emptyArray as [])),
    companies: createSelectOptions(data.companies ?? (emptyArray as [])),
    degrees: data.degrees,
    certificates: data.certificates ? Object.entries(data.certificates).map(([value, label]) => ({ value, label })) : (emptyArray as []),
    teams: createSelectOptions(data.districtTeams ?? (emptyArray as [])),
  };
}

export default function useCommonData() {
  const { api } = useGateway();

  return useQuery({
    queryKey: ['commonData'] as [string],
    queryFn: api.universal.getFrequentlyUsedInformation,
    select: selectCommonData,
    staleTime: ms('1h'),
    placeholderData: {
      districtTeams: [],
      companies: [],
      degrees: {},
      certificates: {},
      careSectors: [],
    },
  });
}

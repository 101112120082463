import { IndividualCareRequestStatus } from '@tallkingconnect/gateway';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/button';
import Error from 'src/components/error/error';
import { Form, FormActions, FormRow, FormSpacer } from 'src/components/form/form';
import { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import LoadingOverlay from 'src/components/loader/loader';
import useCommonData from 'src/hooks/useCommonData';
import { useOnboarding, useUserInformation } from 'src/hooks/users';
import { displayValueFromSelect, formatDateTime } from 'src/utils';
import ActionsMenu from '../actionsMenu';
import styles from '../individualCare.module.scss';
import ReactionForm from '../reactions/form';
import { createSelectOptions } from 'src/hooks/helpers';

const CareRequestView = ({ careRequest = {}, onClose }) => {
  const { t } = useTranslation(['page_individualCare', 'common']);
  const navigate = useNavigate();
  const {
    data: { careSectors, degrees, certificates: certificateOptions },
  } = useCommonData();
  const { hasRole, data: user } = useUserInformation();
  const [showReactionForm, setShowReactionForm] = useState(false);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [careSectorLabel, setCareSectorLabel] = useState('');

  const reactionFormRef = useRef(null);
  const showMaximumRate = hasRole('Admin') || hasRole('CareConsultant');

  const isCaregiver = hasRole('Caregiver');
  const onboarding = useOnboarding({ enabled: isCaregiver });

  const reaction = _.find(careRequest.reactions, { caregiver: { id: user.id } });

  // User can react if they are caregiver and haven't reacted already
  const canReact = isCaregiver && !reaction;

  // hourlyRate will not be visible for the CareConsultant or admin without a value.
  const showHourlyRate = isCaregiver || careRequest.agreedRate > 0;

  useEffect(() => {
    const sectorLabel =
      _.find(careSectors, { value: careRequest.careSectorId })?.label;
    setCareSectorLabel(sectorLabel);
    setDegreeOptions(createSelectOptions(sectorLabel ? degrees[sectorLabel.toLowerCase()] : {})); 

  }, [careRequest.careSectorId, careSectors, degrees], );


  // If Caregiver reacted show rate of reaction
  // else show minimum of Caregivers own rate and maximum rate.
  // Show agreed rate when careConsultant or admin
  const hourlyRate = isCaregiver
    ? reaction
      ? reaction.suggestedRate
      : onboarding.isSuccess
      ? Math.min(careRequest.maximumRate, onboarding.data.personalia?.hourlyRate)
      : null
    : careRequest.agreedRate;

  const closeReactionForm = (isSaved) => {
    setShowReactionForm(false);
    if (isSaved) {
      // refresh page
      navigate(0);
    }
  };

  useEffect(() => {
    if (showReactionForm) {
      reactionFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showReactionForm]);

  const bindModel = (label, key, props = {}) => (
    <LabeledFormInputView label={label} value={_.defaultTo(_.get(careRequest, key), '-')} {...props} />
  );

  return (
    <Form>
      <FormRow>
        {bindModel(t('view.incidentIdentifier', 'Referentienummer'), 'incidentIdentifier')}
        {bindModel(t('view.teamName', 'Team'), 'teamName')}
        <LabeledFormInputView
          label={t('view.status', 'Status')}
          value={t(`constants.individualCareRequestStatusLabels.${careRequest.status}`)}
        />
      </FormRow>
      <FormRow>
        {bindModel(t('view.postcode', 'Postcode'), 'postcode')}
        {bindModel(t('view.hoursPerWeek', 'Aantal uren per week'), 'hoursPerWeek')}
      </FormRow>
      <FormRow>
      <LabeledFormInputView
          label={t('view.startDate', 'Startdatum')}
          value={formatDateTime(careRequest.targetDate, { format: t('common:dateFormat') })}
        />
      {showHourlyRate ? (
          <LabeledFormInputView label={t('view.hourlyRate', 'Uurtarief')} value={hourlyRate} isCurrency={true} />
        ) : (
          <FormSpacer />
        )}
        {showMaximumRate ? (
          bindModel(t('view.maximumRate', 'Maximum uurtarief'), 'maximumRate', { isCurrency: true })
        ) : (
          <FormSpacer />
        )}

      </FormRow>
      <FormRow>
        {bindModel(t('view.education', 'Opleiding'), 'requiredDegree', { options: degreeOptions || [] })}
        { careSectorLabel==='GHZ' && (
          <LabeledFormInputView
            label={t('view.certificates', 'Certificaten')}
            value={displayValueFromSelect(certificateOptions, careRequest.certificates, true)}
          />
        )}
      </FormRow>
      <FormRow>
        {bindModel(t('view.careRequestInfo', 'Zorgvraag'), 'careRequestInfo')}
        {bindModel(t('view.careRequestMoments', 'Zorgmomenten'), 'careRequestMoments')}
        <FormSpacer />
      </FormRow>
      <FormRow>
        <LabeledFormInputView
          label={t('view.createdAt', 'Aangemaakt op')}
          value={formatDateTime(careRequest.createdAt, { format: t('common:dateTimeFormat') })}
        />
        <LabeledFormInputView label={t('view.createdBy', 'Aangemaakt door')} value={careRequest.createdBy} />
        <LabeledFormInputView
          label={t('view.updatedOn', 'Laatst aangepast op')}
          value={formatDateTime(careRequest.updatedAt, { format: t('common:dateTimeFormat') })}
        />
      </FormRow>

      {showReactionForm && (
        <FormRow>
          <div ref={reactionFormRef}>
            <ReactionForm
              onClose={closeReactionForm}
              reaction={{
                individualCareRequestId: careRequest.id,
              }}
              hourlyRate={hourlyRate}
            />
          </div>
        </FormRow>
      )}

      <FormActions>
        <Button secondary onClick={onClose}>
          {t('view.back', 'Terug')}
        </Button>
        {canReact && !showReactionForm && (
          <Button onClick={() => setShowReactionForm(true)}>{t('view.reply', 'Reageer')}</Button>
        )}
      </FormActions>
    </Form>
  );
};

const Page = ({ careRequest: initialCareRequest, error }) => {
  const { t } = useTranslation(['page_individualCare', 'common']);
  const navigate = useNavigate();
  const { hasRole } = useUserInformation();

  const [careRequest, setCareRequest] = useState(initialCareRequest);

  const canEdit = hasRole('CareConsultant') || hasRole('Admin');

  useEffect(() => {
    if (initialCareRequest) {
      setCareRequest(initialCareRequest);
    }
  }, [initialCareRequest]);

  const closeForm = () => navigate('/individualcare/carerequest');

  const updateModel = (nextModel) => {
    if (nextModel.status === IndividualCareRequestStatus.CancelledByCareConsultant) {
      closeForm();
    } else {
      setCareRequest(nextModel);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <h1>{t('view.applicationForIndividualCare', 'Individuele zorgvraag')}</h1>
          {careRequest && canEdit && <ActionsMenu careRequest={careRequest} onChangeModel={updateModel} />}
        </div>
        {error ? (
          <Error>{t('common:error.loadError')}</Error>
        ) : careRequest ? (
          <CareRequestView careRequest={careRequest} onClose={closeForm} />
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default Page;

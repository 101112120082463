import { useState } from 'react';
import { useGateway } from 'src/providers/gateway';
import InputFile from '../input_file/input_file';
import styles from './upload_document.module.scss';
import Information from "src/components/information";

const UploadDocument = (props) => {
  const { api } = useGateway();

  const [isLoading, setIsLoading] = useState(false);

  const postDocument = async (data) => {
    setIsLoading(true);

    try {
      await api.onboardingScreenings.uploadScreeningDocument(data);
      props.getScreeningInformationHandler();
    } catch (err) {
      console.error('Error uploading document:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeFileHandler = (data) => {
    if (typeof data === 'object') {
      postDocument(data);
    }
  };

  const getHeader = (displayName) => {
    switch (displayName) {
      case "Identificatie":
        return <h4>
          {props.displayName}
          <Information>
            Je identiteitsbewijs. Voor en achterkant, geen rijbewijs
          </Information>
        </h4>
      case "VOG":
        return <h4>
            {props.displayName}
        <Information>
          VOG, mag maximaal 3 maanden oud zijn. Je hebt screeningsprofiel 45 Gezondheidszorg en welzijn voor mens en dier nodig.
        </Information>
      </h4>
      case "Diploma":
        return <h4>
          {props.displayName}
          <Information>
            We accepteren alleen diploma's vanuit het DUO-register.
          </Information>
        </h4>
      default:
        return <h4>{displayName}</h4>
    }
  }

  return (
      <div className={styles.container}>
        {getHeader(props.displayName)}
        <InputFile id={props.id} isLoading={isLoading} maxSize={10000000} onChange={onChangeFileHandler} />
    </div>
  );
};

export default UploadDocument;

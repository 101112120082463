import clsx from 'clsx';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { Award, Briefcase, User } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import Card from 'src/components/card/card';
import Checkmark from 'src/components/checkmark';
import Dialog, { YesNoDialog } from 'src/components/dialog/dialog';
import ExternalLink from 'src/components/external_link';
import Information from 'src/components/information/information';
import InputCheckbox from 'src/components/input_checkbox/input_checkbox';
import InputTextArea from 'src/components/input_textarea/input_textarea';
import LoadingOverlay from 'src/components/loader/loader';
import ChangeWorkProfileDialog from 'src/components/screening/change_work_profile_dialog';
import VerdictDocumentList from 'src/components/screening/verdict_documents_list';
import Avatar from 'src/components/user/avatar';
import Flags from 'src/config/flags';
import { useGateway } from 'src/providers/gateway';
import {
  buildBigRegisterUrl,
  buildKvkOrderUrl,
  buildZorgaanbiederPortaalUrl,
  formatCurrency,
  formatDate,
  formatDateTime,
} from 'src/utils';
import { CaregiverProfileStatus } from './constants';
import styles from './screening.module.scss';
import ScreeningStatusLabel from './screeningStatusLabel';

const ScreeningProfile = ({ profile: initialProfile, profileId, caregiverStatus, onClose }) => {
  const { t } = useTranslation(['page_admin', 'common']);
  const { api } = useGateway();

  const [isLoading, setIsLoading] = useState(false);

  const [dialogScreeningVerdictApprove, setDialogScreeningVerdictApprove] = useState(false);
  const [dialogScreeningVerdictReject, setDialogScreeningVerdictReject] = useState(false);
  const [changeWorkProfileDialog, setChangeWorkProfileDialog] = useState(false);
  const [profile, setProfile] = useState(initialProfile);
  const personalia = profile.personalia;
  const [notes, setNotes] = useState(profile.notes || '');
  const [reasonForRejection, setReasonForRejection] = useState(profile.reasonForRejection || '');
  const [profileApproved, setProfileApproved] = useState(profile.personalia.profileApproved);

  const [dirty, setDirty] = useState(false);  

  // Only allow approval if all documents are accepted and have a valid expiration date
  // Diplomas do not have an expiration date, and the other docs must have an expirationDate in the future.
  const valid = useMemo(
    () =>
      profile.onboardingScreeningDocuments.every(
        (doc) =>
          doc.status === 'Approved' &&
          (doc.screeningDocType === 'Diploma' || moment(doc.expirationDate).isAfter(moment()))
      ),
    [profile.onboardingScreeningDocuments]
  );

  const getOnboardingOfCaregiver = async () => {
    setIsLoading(true);

    try {
      const data = await api.onboardingScreenings.getOnboardingOfCaregiver({ caregiverId: profileId });
      if (data) {
        setProfile(data);
      }
    } catch (err) {
      console.error('Error loading onboarding:', err);
      alert(
        t(
          'screening.screeningProfile.somethingWentWrong',
          'Er is iets misgegaan bij het ophalen van de gegevens. Probeer het opnieuw, of neem contact op met de developers.'
        )
      );
    } finally {
      setIsLoading(false);
    }
  };

  const saveScreeningVerdict = async (verdictObject) => {
    setIsLoading(true);

    try {
      await api.onboardingScreenings.saveScreeningVerdict(verdictObject);
      onClose(true);
    } catch (err) {
      console.error('Error saving verdict:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const setCaregiverProfileApproved = useCallback(
    async (value) => {
      setIsLoading(true);

      try {
        await api.caregivers.setCaregiverProfileApproved({
          caregiverId: personalia.caregiverId,
          profileApproved: value,
        });
        setDirty(true);
      } catch (err) {
        console.error('Error saving profile approved:', err);
      } finally {
        setIsLoading(false);
      }
    },
    [api, personalia.caregiverId]
  );

  const [dialogData, setDialogData] = useState(null);

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const confirmChangeCaregiverProfileApproved = useCallback(
    () =>
      showDialog(
        t(
          'screening.screeningProfile.changeProfileApprovedDialogQuestion',
          'Weet je zeker dat je ProfileApproved wilt wijzigen?'
        ),
        async () => {
          const nextValue = !profileApproved;
          await setCaregiverProfileApproved(nextValue);
          setProfileApproved(nextValue);
        }
      ),
    [profileApproved, setCaregiverProfileApproved, t]
  );

  const getDistrictTeamsForWorkingRadiusCount = async (personalia) => {
    try {
      const data = await api.districtTeams.getDistrictTeamsForWorkingRadius({
        latitude: personalia.latitude,
        longitude: personalia.longitude,
        distance: personalia.workingDistanceRange,
      });

      alert(`Teams in range: ${data.districtTeams?.length}`);
    } catch (e) {
      console.error('getDistrictTeamsForWorkingRadius error:', e);
      alert(`An error occurred: ${e}`);
    }
  };

  return (
    <div className={styles.profileScreeningContainer}>
      {isLoading && <LoadingOverlay />}

      {changeWorkProfileDialog && (
        <ChangeWorkProfileDialog
          caregiverId={profileId}
          onCancel={() => setChangeWorkProfileDialog(false)}
          onConfirm={async () => {
            await getOnboardingOfCaregiver();
            setDirty(true);
            setChangeWorkProfileDialog(false);
          }}
          profile = {profile}
      />

      )}
      <Card className={styles.card}>
        <div className={styles.quickInfo}>
          <ul>
            <li>
              <User />
              <span>
                {personalia.firstName || '-'} {personalia.lastName || '-'}
              </span>
            </li>
            <li>
              <Briefcase />
              <span>{profile.careSectors[personalia.careSectorId] || '-'}</span>
            </li>
            <li>
              <Award />
              <span>{personalia.highestDegreeOfEducation}</span>
            </li>
            <li>
              <ScreeningStatusLabel status={caregiverStatus} />
            </li>
            <li className={styles.profileImage}>
              <Avatar src={personalia.profilePictureUrl} large />
            </li>
          </ul>
        </div>

        <div className={styles.profile}>
          <h2>{t('screening.screeningProfile.personalia', 'Personalia')}</h2>

          <ul>
            <li>
              <div>
                {t('screening.screeningProfile.firstName')} / {t('screening.screeningProfile.lastName')}
              </div>
              <div>
                {personalia.firstName || '-'} {personalia.lastName || '-'}
              </div>
            </li>
            <li>
              <div>
                {t('screening.screeningProfile.street')} / {t('screening.screeningProfile.houseNumber')}
              </div>
              <div>
                {personalia.street || '-'} {personalia.houseNumber || '-'}
              </div>
            </li>
            <li>
              <div>
                {t('screening.screeningProfile.postcode')} / {t('screening.screeningProfile.city')}
              </div>
              <div>
                {personalia.postcode || '-'} {personalia.city || '-'}
              </div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.dateOfBirth', 'Geboortedatum')}</div>
              <div>
                {personalia.dateOfBirth ? formatDate(personalia.dateOfBirth, { format: t('common:dateFormat') }) : '-'}
              </div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.phoneNumber', 'Telefoon')}</div>
              <div>{personalia.phoneNumber || '-'}</div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.email', 'Email')}</div>
              <div>{personalia.email || '-'}</div>
            </li>
          </ul>

          <h2>{t('screening.screeningProfile.workProfile', 'Werkprofiel')}</h2>
          <ul>
              {profile && profile.careSectors && personalia.careSectorId && (
              <li>
                <div>{t('screening.screeningProfile.sector', 'Sector')}</div>
                <div>
                  {profile.careSectors[personalia.careSectorId] || '-'}
                </div>
              </li>
              )}
              <li>
                <div>{t('screening.screeningProfile.educationLevel', 'Opleidingsniveau')}</div>
                <div>
                  { personalia.highestDegreeOfEducation}
                </div>
              </li>
              <li>
                <div>{t('screening.screeningProfile.certificates', 'Certificaten')}</div>
                <div>
                  {personalia.certificates}
                </div>
            </li>
            <li>
              {/* TODO Style properly, leave it for now as only Gretha is using this */}
              <div></div>
              <div style={{ justifyContent: 'center' }}>
                { profile?.readyForScreening && (
                  <Button secondary onClick={() => setChangeWorkProfileDialog(true)}>
                    {t('screening.screeningProfile.edit', 'edit')}
                  </Button>
                )}
                </div>
              </li>
            <li>
              <div>{t('screening.screeningProfile.hourlyRate', 'Uurtarief')}</div>
              <div>{formatCurrency(personalia.hourlyRate)}</div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.workingDistance', 'Werkafstand')}</div>
              <div>
                {personalia.workingDistanceRange} km
                {Flags.enableGetDistrictTeamsForWorkingRadiusCount && (
                  <span
                    className={styles.inlineLink}
                    onClick={() => {
                      getDistrictTeamsForWorkingRadiusCount(personalia);
                    }}
                  >
                    {t(
                      'screening.screeningProfile.getDistrictTeamsForWorkingRadiusCount',
                      'Hoeveel teams zitten er in dit werkgebied?'
                    )}
                  </span>
                )}
              </div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.companyName', 'Bedrijfsnaam')}</div>
              <div>{personalia.companyName || '-'}</div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.companyNumber', 'KvK-nummer')}</div>
              <div>
                {personalia.companyNumber ? (
                  <>
                    {personalia.companyNumber}
                    <div>
                      <ExternalLink url={buildKvkOrderUrl(personalia.companyNumber)} label="Kamer van Koophandel" />
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.bigNumber', 'BIG-nummer')}</div>
              <div>
                {personalia.bigNumber ? (
                  <>
                    {personalia.bigNumber}
                    <div>
                      <ExternalLink url={buildBigRegisterUrl(personalia.bigNumber)} label="BIG register" />
                    </div>
                  </>
                ) : (
                  '-'
                )}
              </div>
            </li>
          </ul>

          <h2>{t('screening.screeningProfile.wtza.header', 'Wtza')}</h2>
          <ul>
            <li>
              <div>{t('screening.screeningProfile.wtza.wtzaCompliant', 'Voldoet aan Wtza')}</div>
              <div>
                <Checkmark checked={personalia.wtzaCompliant} />
                <div>
                  {personalia.companyNumber ? (
                    <ExternalLink
                      url={buildZorgaanbiederPortaalUrl(personalia.companyNumber)}
                      label="Zorgaanbiedersportaal"
                    />
                  ) : (
                    t('screening.screeningProfile.wtza.validationLink.companyNumberRequired')
                  )}
                  <br />
                  <em>{t('screening.screeningProfile.wtza.validationLink.info')}</em>
                </div>
              </div>
            </li>
          </ul>

          <h2>{t('screening.screeningProfile.account', 'Account')}</h2>
          <ul>
            <li className={styles.check}>
              <div />
              <InputCheckbox
                label={t(
                  'screening.screeningProfile.agreeWithLatestTerms',
                  'Akkoord met de laatste algemene voorwaarden'
                )}
                disabled
                value={personalia.agreedLatestTermsAndConditions}
              />
            </li>
            <li className={styles.check}>
              <div />
              {/* Profile approved can't be changed when profile status is Onboarding */}
              <InputCheckbox
                label={t('screening.screeningProfile.profileApproved', 'Profiel goedgekeurd')}
                onChange={confirmChangeCaregiverProfileApproved}
                value={profileApproved}
                disabled={caregiverStatus === CaregiverProfileStatus.Onboarding}
              />
            </li>
            <li className={styles.check}>
              <div />
              <InputCheckbox
                disabled
                label={
                  personalia.isActive
                    ? t('screening.screeningProfile.active')
                    : t('screening.screeningProfile.inactive', {
                        reason: profile.personalia.reasonForInactive || '-',
                      })
                }
                value={personalia.isActive}
                className={clsx(!personalia.isActive && styles.inactive)}
              />
            </li>
          </ul>
        </div>

        <h2>{t('screening.screeningProfile.documents', 'Documenten')}</h2>
        {profile?.onboardingScreeningDocuments ? (
          <VerdictDocumentList
            screeningCompleted={profile.screeningCompleted}
            documents={profile.onboardingScreeningDocuments}
            screeningId={profile.id}
            readyForScreening={profile.readyForScreening}
            onSaveScreeningDocumentVerdict={getOnboardingOfCaregiver}
            onUpdate={(updatedDocument) => {
              setProfile({
                ...profile,
                onboardingScreeningDocuments: profile.onboardingScreeningDocuments.map((doc) =>
                  doc.id === updatedDocument.id ? updatedDocument : doc
                ),
              });
            }}
          />
        ) : (
          <div>
            {t(
              'screening.screeningProfile.noDocuments',
              'Het lijkt erop dat deze zorgverlener geen documenten heeft geüpload.'
            )}
          </div>
        )}

        {profile &&
          (profile.readyForScreening ? (
            <Trans t={t} i18nKey="screening.screeningProfile.notes">
              <h2>
                Notities
                <Information>Zijn er zaken bij het keuren die je ergens kwijt wilt? Noteer ze hierin.</Information>
              </h2>
              <InputTextArea
                placeholder={t('screening.screeningProfile.notesPlaceholder', 'Plaats hier eventuele notities...')}
                onChange={setNotes}
                value={notes}
              />
            </Trans>
          ) : (
            <div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '1rem', width: '50%' }}>
                <h2>{t('screening.screeningProfile.notesHeading', 'Notities')}</h2>
                <p>{profile.notes}</p>
              </div>

              <div style={{ paddingRight: '1rem', width: '50%' }}>
                <h2>{t('screening.screeningProfile.reasonForRejectionHeading', 'Toelichting afkeuren')}</h2>
                <p>{profile.reasonForRejection}</p>
              </div>
            </div>
          ))}

        <hr />

        <div className={styles.screeningInfo}>
          <ul>
            {profile.screenedBy && (
              <>
                <li>
                  <div>{t('screening.screeningProfile.screenedBy', 'Gescreend door')}: </div>
                  <div>{profile.screenedBy}</div>
                </li>
                <li>
                  <div>{t('screening.screeningProfile.screenedAt', 'Gescreend op')}: </div>
                  <div>
                    {profile.screenedAt
                      ? formatDateTime(profile.screenedAt, { format: t('common:dateTimeFormat') })
                      : '-'}
                  </div>
                </li>
              </>
            )}
            <li>
              <div>{t('screening.screeningProfile.createdAt', 'Gemaakt op')}: </div>
              <div>{formatDateTime(profile.createdAt, { format: t('common:dateTimeFormat') })}</div>
            </li>
            <li>
              <div>{t('screening.screeningProfile.updatedAt', 'Laatste aanpassing op')}: </div>
              <div>{formatDateTime(profile.updatedAt, { format: t('common:dateTimeFormat') })}</div>
            </li>
          </ul>
        </div>

        <div style={{ display: 'flex', justifyContent: profile?.readyForScreening ? 'space-between' : 'center' }}>
          <Button
            secondary
            onClick={() => {
              onClose(dirty);
            }}
          >
            {t('screening.screeningProfile.close', 'Sluiten')}
          </Button>

          {profile?.readyForScreening && (
            <>
              <Button
                invert
                disabled={!profile?.readyForScreening}
                onClick={() => {
                  setDialogScreeningVerdictReject(true);
                }}
              >
                {t('screening.screeningProfile.reject', 'Afkeuren')}
              </Button>

              <Button
                invert
                disabled={!profile?.readyForScreening || !valid}
                onClick={() => {
                  setDialogScreeningVerdictApprove(true);
                }}
              >
                {t('screening.screeningProfile.approve', 'Goedkeuren')}
              </Button>
            </>
          )}
        </div>

        {dialogScreeningVerdictReject && (
          <Dialog
            onConfirm={() => {
              saveScreeningVerdict({
                id: profile.id,
                notes: notes || '',
                screeningCompleted: false,
                reasonForRejection: reasonForRejection || '',
              });
              setDialogScreeningVerdictReject(false);
            }}
            onCancel={() => {
              setDialogScreeningVerdictReject(false);
            }}
            invertAction
            labelQuestion={
              <Trans t={t} i18nKey="screening.screeningProfile.dialogScreeningVerdictReject.labelQuestion">
                Je staat op het punt om het profiel van{' '}
                <b>
                  {{ firstName: personalia.firstName }} {{ lastName: personalia.lastName }}
                </b>{' '}
                af te keuren.
                <br />
                <br />
                Geef hiervoor een reden op.
              </Trans>
            }
            labelCancel={t('screening.screeningProfile.dialogScreeningVerdictReject.labelCancel', 'Annuleren')}
            labelConfirm={t('screening.screeningProfile.dialogScreeningVerdictReject.labelConfirm', 'Afkeuren')}
          >
            <InputTextArea
              placeholder={t(
                'screening.screeningProfile.dialogScreeningVerdictReject.reasonForDisapprovalPlaceholder',
                'Geef hier je reden voor afkeuren op...'
              )}
              onChange={setReasonForRejection}
            />
          </Dialog>
        )}

        {dialogScreeningVerdictApprove && (
          <Dialog
            onConfirm={() => {
              saveScreeningVerdict({
                id: profile.id,
                notes: notes || '',
                screeningCompleted: true,
                screeningDocuments: profile.onboardingScreeningDocuments,
              });
              setDialogScreeningVerdictApprove(false);
            }}
            onCancel={() => {
              setDialogScreeningVerdictApprove(false);
            }}
            invertAction
            labelQuestion={
              <Trans t={t} i18nKey="screening.screeningProfile.dialogScreeningVerdictApprove.labelQuestion">
                Je staat op het punt om het profiel van{' '}
                <b>
                  {{ firstName: personalia.firstName }} {{ lastName: personalia.lastName }}
                </b>{' '}
                goed te keuren.
                <br />
                <br />
                Weet je het zeker?
              </Trans>
            }
            labelCancel={t('screening.screeningProfile.dialogScreeningVerdictApprove.labelCancel', 'Annuleren')}
            labelConfirm={t('screening.screeningProfile.dialogScreeningVerdictApprove.labelConfirm', 'Goedkeuren')}
          />
        )}
      </Card>

      {dialogData && <YesNoDialog {...dialogData} />}
    </div>
  );
};

export default ScreeningProfile;

import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AddressForm from 'src/components/address/form';
import { useAddressValidation, validateAddress } from 'src/components/address/util';
import Button from 'src/components/button/button';
import DatePicker from 'src/components/datepicker/datepicker';
import { YesNoDialog } from 'src/components/dialog/dialog';
import DocumentsForm from 'src/components/documents/form';
import { Form, FormActions, FormRow } from 'src/components/form/form';
import LabeledFormInput from 'src/components/form/labeled_form_input';
import Information from 'src/components/information';
import InputCheckbox from 'src/components/input_checkbox';
import InputNumber, { InputCurrency } from 'src/components/input_number';
import InputText from 'src/components/input_text';
import InputTextArea from 'src/components/input_textarea';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select/select';
import { useDocumentsValidation } from 'src/hooks/documents';
import { usePostingValidation, useSavePostingMutation } from 'src/hooks/postings';
import useCommonData from 'src/hooks/useCommonData';
import { PostingDocumentTypeOptions } from 'src/pages/postings/constants';
import { useGateway } from 'src/providers/gateway';
import { extractTeamAddress } from 'src/utils/address';
import { emptyPosting } from './constants';
import styles from './postings.module.scss';
import { createSelectOptions } from 'src/hooks/helpers';

const LabelWithInformation = ({ label, information }) => (
  <span>
    {label}
    <Information>{information}</Information>
  </span>
);

const PostingForm = ({ posting: initialPosting = emptyPosting, edit: _edit = false }) => {
  const { t } = useTranslation('page_postings');
  const navigate = useNavigate();
  const { api } = useGateway();

  const [posting, setPosting] = useState(initialPosting);
  const postingValidation = usePostingValidation(posting);
  const addressValidation = useAddressValidation(posting.address || {});
  const documentsValidation = useDocumentsValidation(posting.documents || []);
  const valid = postingValidation.valid && addressValidation.valid && documentsValidation.valid;
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState(posting.certificates ? posting.certificates.replaceAll(', ', ',').split(',') : []);
  const [careSectorLabel, setCareSectorLabel] = useState('');
  const {
    data: { careSectors, degrees, certificates: certificateOptions, teams },
  } = useCommonData();


  // Set selectOPtions
  useEffect(() => {
    const sectorLabel =
      _.find(careSectors, { value: posting.careSectorId })?.label;
    setCareSectorLabel(sectorLabel);
    setDegreeOptions(createSelectOptions(sectorLabel ? degrees[sectorLabel.toLowerCase()] : {})); 

  }, [posting.careSectorId, careSectors, degrees], );

  const updateModel = (key) => (value) =>
    setPosting((prevState) => {
      const updatedModel = {
        ...prevState,
        [key]: value,
      };

      if (key === 'districtTeamId') {
        const teamData = _.find(teams, { value }).data;
        _.merge(updatedModel, {
          careSectorId: teamData.careSectorId,
          careType: teamData.typeOfCare,
          address: extractTeamAddress(teamData),
        });
      }

      return updatedModel;
    });
  
  const updateAddress = (address) => {
    setPosting((prevState) => ({ ...prevState, address }));

    // Update location
    debouncedUpdateLocation(address);
  };

  const updateLocation = useCallback(
    async (address) => {
      if (validateAddress(address)) {
        try {
          const newAddress = await api.universal.geocode(address);
          setPosting((prevState) => ({
            ...prevState,
            address: {
              ...prevState.address,
              lat: newAddress.lat,
              lng: newAddress.lng,
            },
          }));
        } catch (err) {
          console.error('Error geocoding:', err);
          setPosting((prevState) => ({
            ...prevState,
            address: { ...prevState.address, lat: null, lng: null },
          }));
        }
      } else {
        setPosting((prevState) => ({
          ...prevState,
          address: { ...prevState.address, lat: null, lng: null },
        }));
      }
    },
    [api]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateLocation = useCallback(_.debounce(updateLocation, 1000), [updateLocation]);

  const bindModel = (label, key, defaultValue, component, props = {}) => (
    <LabeledFormInput
      component={component}
      label={label}
      value={_.defaultTo(_.get(posting, key), defaultValue)}
      onChange={updateModel(key)}
      {...props}
    />
  );

  const postingMutation = useSavePostingMutation({
    onSuccess: (data) => {
      updateModel({ id: data.id });
      navigate(`/postings/${data.id}`);
    },
    onError: (err) => {
      console.error('Error saving posting:', err);
    },
  });

  const save = () => {    
    postingMutation.mutate({
      posting: {
        ...posting,
        // Flatten certificates
        certificates: selectedCertificates.length>0 ? selectedCertificates.reduce((result,item) =>`${result},${item}`) : '',
        // Flatten new file upload data
        documents: (posting.documents || []).map(({ blobData = {}, ...doc }) => ({
          ...doc,
          ...blobData,
        })),
      },
    });
  };

  return (
    <>
      <Form className={styles.postingForm}>
        <FormRow>
          {bindModel(t('form.startDate', 'Startdatum'), 'startDate', '', DatePicker)}
          {bindModel(t('form.endDate', 'Einddatum'), 'endDate', '', DatePicker)}
        </FormRow>
        <FormRow>
          {bindModel(t('form.hoursPerWeek', 'Uren per week'), 'hoursPerWeek', '', InputNumber)}
          {bindModel(
            <LabelWithInformation
              label={t('form.proposedHourlyRate', 'Voorstel uurtarief')}
              information={t('form.thisIsAnAllInclusiveRate', 'Dit is een all-in-tarief')}
            />,
            'suggestedRate',
            '',
            InputCurrency
          )}
        </FormRow>
        <FormRow>
          {bindModel(t('form.team', 'Team'), 'districtTeamId', null, Select, {
            options: teams || [],
          })}
          {bindModel(t('form.healthCareType', 'Zorgtype'), 'careType', '', InputText, {
            readOnly: true,
            title: t(
              'form.careTypeIsDependentOfTheTeamAndThereforeNotCustomizable',
              'Zorgtype is afhankelijk van het team en daardoor niet aanpasbaar'
            ),
          })}
          {bindModel(t('form.healthCareSector', 'Zorgsector'), 'careSectorId', null, Select, {
            options: careSectors || [],
          })}
        </FormRow>
        <FormRow>
          {bindModel(t('form.minimumEducation', 'Opleiding minimaal'), 'requiredDegree', null, Select, {
            options: degreeOptions || [],
          })}
          { careSectorLabel==='GHZ' && (
            <LabeledFormInput
              component={Select}
              label={t('form.certificates')}
              value={selectedCertificates} 
              onChange={setSelectedCertificates}
              multiple={true}
              options={certificateOptions}
            />
          )}
        </FormRow>

        <FormRow>
          {/* <label>Locatie</label> */}
          <AddressForm address={posting.address || {}} onChange={updateAddress} />
        </FormRow>

        <FormRow>
          {bindModel(t('form.description', 'Omschrijving (verplicht)'), 'description', '', InputTextArea)}
        </FormRow>
        <FormRow>
          {bindModel(null, 'resumeRequired', false, InputCheckbox, { label: t('form.cvRequired', 'CV vereist') })}
        </FormRow>

        {posting.documents && (
          <DocumentsForm
            label={t('form.additionalDocuments', 'Aanvullende documenten')}
            documents={posting.documents}
            onChange={updateModel('documents')}
            typeOptions={PostingDocumentTypeOptions(t)}
          />
        )}

        <FormActions>
          <Button secondary onClick={() => setShowConfirmCancel(true)}>
            {t('form.cancel', 'Annuleren')}
          </Button>
          <Button disabled={!valid} onClick={save}>
            {t('form.save', 'Opslaan')}
          </Button>
        </FormActions>

        {showConfirmCancel && (
          <YesNoDialog
            onConfirm={() => navigate(posting.id ? `/postings/${posting.id}` : '/postings')}
            onCancel={() => setShowConfirmCancel(false)}
            labelQuestion={t(
              'form.showConfirmCancelDialogQuestion',
              'Weet je het zeker? Eventuele wijzigingen worden niet opgeslagen.'
            )}
          />
        )}
      </Form>

      {postingMutation.isLoading && <LoadingOverlay />}
    </>
  );
};

export default PostingForm;

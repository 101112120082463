import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Sticky from 'react-stickynode';
import { useWindowSize } from 'react-use';
import A from 'src/components/a';
import Button from 'src/components/button/button';
import Card from 'src/components/card/card';
import DatePicker from 'src/components/datepicker/datepicker';
import Information from 'src/components/information/information';
import InputCheckbox from 'src/components/input_checkbox';
import InputFileProfilePicture from 'src/components/input_file/input_file_profile_picture';
import InputIban from 'src/components/input_iban/input_iban';
import InputCurrency from 'src/components/input_number/input_currency';
import InputNumber from 'src/components/input_number/input_number';
import InputPhone from 'src/components/input_number/input_phone';
import InputTax from 'src/components/input_tax/input_tax';
import InputText from 'src/components/input_text/input_text';
import LoadingOverlay from 'src/components/loader/loader';
import RejectionProfile from 'src/components/onboarding/rejection_profile';
import ShowDocument from 'src/components/onboarding/show_document';
import UploadDocument from 'src/components/onboarding/upload_document';
import ProgressElement from 'src/components/progress_element/progress_element';
import { ScreeningDocumentStatus } from 'src/components/screening/constants';
import Select from 'src/components/select';
import SelectCountry, { countryOptionsByLanguage } from 'src/components/select/select_country';
import Avatar from 'src/components/user/avatar';
import useCommonData from 'src/hooks/useCommonData';
import UserHelpBar from 'src/components/user_help_bar/user_help_bar';
import i18n from 'src/config/i18n';
import { useUserInformation } from 'src/hooks/users';
import { useGateway } from 'src/providers/gateway';
import styleConfig from 'src/styles/config.module.scss';
import { formatCurrency, formatDate } from 'src/utils';
import { extractCaregiverAddress } from 'src/utils/address';
import { displayValueFromSelect } from 'src/utils/format';
import AwaitingScreening from './awaitingScreening';
import styles from './onboarding.module.scss';
import ScreeningComplete from './screeningComplete';
import WelcomeScreen from './welcomeScreen';

const ADDRESS_KEYS = ['street', 'houseNumber', 'postcode', 'city', 'countryCode'];
const isAddressKey = (key) => ADDRESS_KEYS.includes(key);
const isAddressComplete = (address) => _.every(ADDRESS_KEYS, (key) => !_.isEmpty(address[key]));
const isLocationValid = (personalia) => Boolean(personalia.latitude) && Boolean(personalia.longitude);

const Onboarding = () => {
  const { t } = useTranslation(['page_onboarding', 'common']);
  const { refetch: refetchUser } = useUserInformation();
  const { api } = useGateway();
  const {
    data: { certificates: certificateOptions },
    isFetched: commonDataLoaded,
  } = useCommonData();

  const { width: windowWidth } = useWindowSize();
  const isNarrow = windowWidth <= parseInt(styleConfig.mediumScreen, 10);
  const remSize = parseInt(getComputedStyle(document.documentElement).fontSize, 10); /* 1rem == 1 * font size */

  const [educationOptions, setEducationOptions] = useState([]);
  const [selectableCareSectors, setSelectableCareSectors] = useState(null);
  const [documents, setDocuments] = useState();
  const [initialLoad, setInitialLoad] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showReadyForScreeningButton, setShowReadyForScreeningButton] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);
  const [readyForScreening, setReadyForScreening] = useState(null);
  const [onboardingResult, setOnboardingResult] = useState(null);
  const [reasonForRejection, setReasonForRejection] = useState(null);
  const [rejectedDocuments, setRejectedDocuments] = useState(null);
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState(true);
  const [ibanIsValid, setIbanIsValid] = useState(true);
  const [taxNumberIsValid, setTaxNumberIsValid] = useState(true);
  const [validCareSectors, setValidCareSectors] = useState([]);
  const [careSectorLabel, setCareSectorLabel] = useState('');

  const [progressStatus, setProgressStatus] = useState(0);

  const [selectedDegree, setSelectedDegree] = useState('');
  const [selectedCertificates, setSelectedCertificates] = useState([]);

  const [onboardingProfile, setOnboardingProfile] = useState({
    onboardingScreeningDocuments: [],
    personalia: {
      firstName: '',
      lastName: '',
      street: '',
      houseNumber: '',
      postcode: '',
      city: '',
      countryCode: process.env.REACT_APP_DEFAULT_COUNTRY_CODE || '',
      profilePictureUrl: '',
      dateOfBirth: null,
      latitude: 0,
      longitude: 0,
      companyName: '',
      companyNumber: '',
      bigNumber: '',
      taxNumber: '',
      iban: '',
      ibanOwnerName: '',
      highestDegreeOfEducation: '',
      careSectorId: '',
      hourlyRate: '',
      workingDistanceRange: '',
    },
    newProfilePictureBase64String: '',
    newProfilePictureContentType: '',
  });

  const [lastQueriedAddress, setLastQueriedAddress] = useState({
    street: '',
    houseNumber: '',
    postcode: '',
    city: '',
    countryCode: '',
  });

  const [companyInfoVisible, setCompanyInfoVisible] = useState(false);
  const [personalInfoVisible, setPersonalInfoVisible] = useState(false);
  const [profilePictureUrlVisible, setProfilePictureUrlVisible] = useState(false);
  const [educationVisible, setEducationVisible] = useState(false);
  const [uurtariefVisible, setUurtariefVisible] = useState(false);
  const [werkafstandVisible, setWerkafstandVisible] = useState(false);

  const [documentRequiredScreeningTypes, setDocumentRequiredScreeningTypes] = useState([]);

  const [isGeocoding, setIsGeocoding] = useState(false);

  const isPersonaliaComplete = useCallback(
    (personalia) =>
      !(
        _.isEmpty(personalia.firstName) ||
        _.isEmpty(personalia.lastName) ||
        !isAddressComplete(extractCaregiverAddress(personalia)) ||
        !isLocationValid(personalia) ||
        _.isEmpty(personalia.phoneNumber) ||
        !phoneNumberIsValid ||
        !personalia.dateOfBirth
      ),
    [phoneNumberIsValid]
  );

  const isValidCareSector = (careSector) => validCareSectors.some((sector) => sector.value === careSector);

  const isCompanyInfoComplete = useCallback(
    (personalia) =>
      !(
        _.isEmpty(personalia.companyName) ||
        _.isEmpty(personalia.companyNumber) ||
        _.isEmpty(personalia.ibanOwnerName) ||
        !ibanIsValid ||
        // If a tax number is given, it must be valid
        !taxNumberIsValid ||
        // BIG number is only required for certain education levels
        (_.isEmpty(personalia.bigNumber) && ['MBO4', 'HBO'].includes(personalia.highestDegreeOfEducation))
      ),
    [taxNumberIsValid, ibanIsValid]
  );

  const doProgressionChecks = useCallback(
    (personalia, requiredDocumentCount, filledDocumentCount, validCareSectors) => {
      const fieldProgress = {
        highestDegreeOfEducation: personalia.highestDegreeOfEducation !== 'None',
        careSectorId:
          !_.isEmpty(personalia.careSectorId) &&
          validCareSectors.some((sector) => sector.value === personalia.careSectorId),
        hourlyRate: personalia.hourlyRate > 0,
        workingDistanceRange: personalia.workingDistanceRange > 0,
        profilePictureUrl: Boolean(personalia.profilePictureUrl),
        personalInfo: isPersonaliaComplete(personalia),
        companyInfo: isCompanyInfoComplete(personalia),
        wtzaCompliant: Boolean(personalia.wtzaCompliant),
      };

      const progress = _.size(_.filter(fieldProgress)) + filledDocumentCount;
      const total = _.size(fieldProgress) + requiredDocumentCount;
      const progressPercent = Math.round((progress / total) * 100);
      setProgressStatus(progressPercent);

      return progressPercent >= 100;
    },
    [isCompanyInfoComplete, isPersonaliaComplete]
  );

  const scrollTo = (top, delay = 100) =>
    setTimeout(() => {
      if (top !== null) {
        window.scrollTo({
          top,
          left: 0,
          behavior: 'smooth',
        });
      }
    }, delay);

  const getScreeningInformation = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await api.onboardingScreenings.getOnboardingScreeningInformation();

      if (data) {
        setOnboardingResult(data);
        setReadyForScreening(data.readyForScreening);
        setReasonForRejection(data.reasonForRejection);
        setRejectedDocuments(
          data.onboardingScreeningDocuments.filter(
            (documentItem) => documentItem.status === ScreeningDocumentStatus.Rejected
          )
        );

        // All care sectors that the backend currently supports
        const allCareSectors = Object.entries(data.careSectors).map(([value, label]) => ({ value, label }));

        // Care sectors that we currently want to support
        const validCareSectors = allCareSectors.filter((c) => ['VVT', 'GHZ'].includes(c.label));
        setValidCareSectors(validCareSectors);
        // Selectable sectors, which includes the already chosen careSector (which might be invalid)
        setSelectableCareSectors(
          allCareSectors.filter((c) => ['VVT', 'GHZ'].includes(c.label) || c.value === data?.personalia?.careSectorId)
        );

        setCareSectorLabel( _.isEmpty(data.personalia.careSectorId) ? '' : data.careSectors[data.personalia.careSectorId]);

        let nextOnboardingProfile = {};        
        if (data.id) {
          nextOnboardingProfile = {
            ...nextOnboardingProfile,
            id: data.id,
          };
        }

        if (data.onboardingScreeningDocuments) {
          nextOnboardingProfile = {
            ...nextOnboardingProfile,
            onboardingScreeningDocuments: data.onboardingScreeningDocuments,
          };

          setDocuments(data.onboardingScreeningDocuments);
        }

        // TODO: remove this once backend checks entire profile (including picture, hour rate, working distance)
        let readyForScreeningAccordingToFrontend = false;

        if (data.personalia) {
          nextOnboardingProfile = {
            ...nextOnboardingProfile,
            personalia: data.personalia,
          };
          setLastQueriedAddress(extractCaregiverAddress(data.personalia));

          setSelectedDegree(data.personalia.highestDegreeOfEducation);
          
          setSelectedCertificates(data.personalia.certificates ? data.personalia.certificates.replaceAll(', ', ',').split(',') : [])

          readyForScreeningAccordingToFrontend = doProgressionChecks(
            data.personalia,
            data.requiredDocumentTypes.length,
            data.onboardingScreeningDocuments.filter((doc) => ![ScreeningDocumentStatus.Rejected].includes(doc.status))
              .length,
            validCareSectors
          );
        }

        setOnboardingProfile((prevState) => ({ ...prevState, ...nextOnboardingProfile }));

        setDocumentRequiredScreeningTypes(data.requiredDocumentTypes);
        setShowReadyForScreeningButton(data.showReadyForScreeningButton && readyForScreeningAccordingToFrontend);
        setShowWelcomeScreen(data.showWelcomeScreen);
      }
    } catch (err) {
      console.error('Error loading screening information:', err);
    } finally {
      setIsLoading(false);

      // Scroll to last known scroll position.
      // Needs to be done here because without any data, no form is displayed, and hence the page height differs.
      scrollTo(lastScrollTop);
    }
  }, [api, doProgressionChecks, lastScrollTop, t]);

  useEffect(() => {
    if (!isLoading && onboardingProfile.personalia.careSectorId) {
      let degrees = onboardingResult.degrees[onboardingResult.careSectors[onboardingProfile.personalia.careSectorId].toLowerCase()];
      setEducationOptions(
        Object.entries(degrees).map(([value, label]) => ({ value, label }))
      );
    }
  }, [isLoading,onboardingResult, onboardingProfile, getScreeningInformation]);

  const deleteScreeningDocument = async (id) => {
    setIsLoading(true);

    try {
      await api.onboardingScreenings.deleteScreeningDocument({ id });

      setDocuments(documents.filter((doc) => doc.id !== id));
    } finally {
      setIsLoading(false);
    }
  };

  const saveOnboardingScreeningInformation = async (data = onboardingProfile) => {
    setIsLoading(true);

    try {
      await api.onboardingScreenings.saveOnboardingScreeningInformation(data);
      if (!_.isEmpty(data.newProfilePictureBase64String)) {
        refetchUser();
      }

      // Close open panels after save
      // TODO: this fix/workaround should really not be necessary
      setCompanyInfoVisible(false);
      setPersonalInfoVisible(false);
      setProfilePictureUrlVisible(false);
      setUurtariefVisible(false);
      setEducationVisible(false);
      setWerkafstandVisible(false);
    } catch (err) {
      console.error('Error saving screening information:', err);
    } finally {
      getScreeningInformation();
    }
  };

  const saveChanges = async () => {
    await saveOnboardingScreeningInformation();
  };

  // Mark profile as ready for screening (triggered from the "ready for screening" button).
  const startScreening = async () => {
    const nextProfile = {
      ...onboardingProfile,
      readyForScreening: true,
    };

    setOnboardingProfile(nextProfile);
    saveOnboardingScreeningInformation(nextProfile);
  };

  const updateLocation = useCallback(
    async (personalia) => {
      const address = extractCaregiverAddress(personalia);
      let newAddress = {
        lat: 0,
        lng: 0,
      };

      setIsGeocoding(true);
      if (isAddressComplete(address)) {
        try {
          // TODO: geocode result (from server) may contain information which could be used to correct e.g. postcode.
          // Right now, filling in an incorrect postcode but a correct address may result in a complete response from geocoding, but will NOT warn the user about any possible mistakes.
          newAddress = await api.universal.geocode(address);
        } catch (error) {
          // TODO: handle situation where we can't find a location given the address
          // For now, simply set to empty values
        }
      }
      // NB: always set `isGeocoding` back to false, with the `debouncedUpdateLocation` calls in mind
      setIsGeocoding(false);

      setLastQueriedAddress(address);
      setOnboardingProfile((prevState) => ({
        ...prevState,
        personalia: {
          ...prevState.personalia,
          latitude: newAddress.lat,
          longitude: newAddress.lng,
        },
      }));
    },
    [api]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateLocation = useCallback(_.debounce(updateLocation, 1000), [updateLocation]);
  
  // Change education and certificates
  const changeEducation = () => {
    const nextState = {
      ...onboardingProfile,
      personalia: {
        ...onboardingProfile.personalia,
        highestDegreeOfEducation: selectedDegree,
        certificates: selectedCertificates.length>0 ? selectedCertificates.reduce((result, item) => `${result},${item}`): "",
      },
    };
    setOnboardingProfile(nextState);
    saveOnboardingScreeningInformation(nextState);
  }



  const changeOnboardingProfileInfo = _.curry((param, isPersonalia, value) => {
    let passValue = value;
    if (param === 'careSectorId') {
      const nextState = {
        ...onboardingProfile,
        personalia: {
          ...onboardingProfile.personalia,
          [param]: passValue,
          highestDegreeOfEducation: 'None',
        },
      };
      setOnboardingProfile(nextState);
      saveOnboardingScreeningInformation(nextState);
      setCareSectorLabel(selectableCareSectors.find(c => c.key === onboardingProfile.personalia.careSector).label); 
      return;
    }

    // Some values are input as numbers but stored as string, so they need conversion.
    if (param === 'companyNumber' || param === 'bigNumber') {
      passValue = _.isNil(passValue) ? '' : String(passValue);
    }

    if (isPersonalia) {
      const nextPersonalia = {
        ...onboardingProfile.personalia,
        [param]: passValue,
      };
      setOnboardingProfile((prevState) => ({
        ...prevState,
        personalia: nextPersonalia,
      }));

      if (isAddressKey(param) && !_.isEqual(lastQueriedAddress, extractCaregiverAddress(nextPersonalia))) {
        // indicate that we're geocoding (even though the actual function is debounced) to disable the save button
        setIsGeocoding(true);
        // no need to await here
        debouncedUpdateLocation(nextPersonalia);
      }
    } else {
      if (param === 'newProfilePictureBase64String') {
        setOnboardingProfile((prevState) => ({
          ...prevState,
          newProfilePictureBase64String: value.split('base64,')[1],
          newProfilePictureContentType: value.split(':')[1].split(';')[0],
        }));
      }
    }
  });

  // Initial load
  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
      getScreeningInformation();
    }
  }, [initialLoad, getScreeningInformation]);

  const setScroll = (event) => {
    const scrollTop = event.target.defaultView.pageYOffset;
    if (scrollTop !== 0) {
      setLastScrollTop(scrollTop);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', setScroll);
    return () => {
      document.removeEventListener('scroll', setScroll);
    };
  }, []);

  const address = extractCaregiverAddress(onboardingProfile.personalia);
  const addressComplete = isAddressComplete(address);
  const locationValid = isLocationValid(onboardingProfile.personalia);

  return (
    <div className={styles.container}>
      {isLoading && <LoadingOverlay />}

      {onboardingResult ? (
        <>
          {showWelcomeScreen ? (
            <WelcomeScreen name={onboardingProfile.personalia.firstName} proceed={() => setShowWelcomeScreen(false)} />
          ) : onboardingResult.screeningCompleted ? (
            <ScreeningComplete />
          ) : readyForScreening ? (
            <AwaitingScreening name={onboardingProfile.personalia.firstName} />
          ) : (
            <>
              <div className={styles.introductionContainer}>
                {onboardingProfile.personalia.firstName && onboardingProfile.personalia.lastName ? (
                  <h1>
                    {t('onboarding.welcome')},{' '}
                    {onboardingProfile.personalia.firstName + ' ' + onboardingProfile.personalia.lastName}!
                  </h1>
                ) : (
                  <h1>{t('onboarding.welcome')}!</h1>
                )}
                <p>
                  <Trans t={t} i18nKey="onboarding.formInfo">
                    Vul hieronder je beroeps- en bedrijfsgegevens aan (voor meer info, klik{' '}
                    <A href={t('onboarding.formHelpLink')}>hier</A>
                    ). Zodra alles compleet is, kunnen we de screening starten.{' '}
                    <strong>Heb je niet alle gegevens direct beschikbaar?</strong> Via de link in de e-mail kan je later
                    verder gaan waar je gebleven was.
                  </Trans>
                </p>
              </div>
              {(!_.isEmpty(reasonForRejection) || (rejectedDocuments && rejectedDocuments.length > 0)) && (
                <RejectionProfile reasonForRejection={reasonForRejection} rejectedDocuments={rejectedDocuments} />
              )}
              <div className={styles.userDataContainer}>
                <div className={styles.statusContainer}>
                  <Sticky top={(isNarrow ? 4 : 5.5) * remSize} innerZ={2}>
                    <Card data-status-container-card>
                      <ProgressElement status={progressStatus} placeholder={t('onboarding.complete', 'compleet')} />
                      <Button disabled={!showReadyForScreeningButton} onClick={startScreening}>
                        {t('onboarding.startScreening')}
                      </Button>
                    </Card>
                    {!isNarrow && <UserHelpBar className={styles.userHelpBar} />}
                  </Sticky>
                  {isNarrow && <UserHelpBar className={styles.userHelpBar} />}
                </div>
                <div className={styles.userInfoContainer}>
                  {!isLoading && onboardingProfile.personalia && (
                    <>
                      <Card
                        data-status-filled={
                          !_.isEmpty(onboardingProfile.personalia.careSectorId) &&
                          isValidCareSector(onboardingProfile.personalia.careSectorId)
                        }
                      >
                        {selectableCareSectors && selectableCareSectors.length > 0 && (
                          <>
                            <h4>
                              {t('onboarding.whichSector', 'In welke sector werk je?')}
                              {!isValidCareSector(onboardingProfile.personalia.careSectorId) && (
                                <Information className={styles.errorInfo}>
                                  {t(
                                    'onboarding.invalidSector',
                                    'De sector die je hebt gekozen wordt niet meer ondersteund'
                                  )}
                                </Information>
                              )}
                            </h4>
                            <Select
                              value={onboardingProfile.personalia.careSectorId}
                              onChange={changeOnboardingProfileInfo('careSectorId', true)}
                              options={selectableCareSectors}
                              placeholder={t('onboarding.chooseIndustry', 'Kies een sector')}
                            />
                          </>
                        )}
                      </Card>

                      {/* HIDE ALL INPUT WHEN SECTOR HAS NOT BEEN CHOSEN */}
                      {onboardingProfile.personalia.careSectorId && (
                        <>
                          <Card
                              data-status-filled={(onboardingProfile.personalia.highestDegreeOfEducation !== 'None')}
                          >
                            {commonDataLoaded && educationOptions && educationOptions.length > 0 && (
                              <>
                                <h4>
                                  {t('onboarding.whatEducation', 'Welke opleiding heb je?')}
                                  <span
                                    onClick={(event) =>
                                      event.currentTarget === event.target && setEducationVisible(!educationVisible)
                                    }
                                  >
                                    {!educationVisible
                                      ? t('onboarding.change', 'wijzig')
                                      : t('onboarding.close', 'sluiten')}
                                  </span>
                                </h4>
                                
                                {educationVisible ? (
                                  <>
                                    <label>
                                      {t('onboarding.diploma', 'zorgdiploma')}
                                    </label>
                                    <Select
                                      value={selectedDegree}
                                      onChange={setSelectedDegree}
                                      options={educationOptions}
                                      placeholder={t('onboarding.chooseEducation', 'Kies je opleiding')}
                                    />                                          
                                    {  careSectorLabel === 'GHZ' && (
                                      <>
                                        <label>
                                          {t('onboarding.certificates', 'certificaten')}
                                        </label>
                                        <Select
                                          options={certificateOptions}
                                          value={selectedCertificates}
                                          onChange={setSelectedCertificates}
                                          multiple={true}
                                          showClear={true}
                                          placeholder={t('onboarding.chooseCertificates', 'Kies je certificaten')}
                                        />
                                      </>
                                    )}
                                    <Button onClick={changeEducation}>
                                      {t('onboarding.save', 'opslaan')}
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    {(_.isEmpty(onboardingProfile.personalia.highestDegreeOfEducation) || onboardingProfile.personalia.highestDegreeOfEducation==='None') ? (
                                      <h5>
                                        <i>{t('onboarding.enterEducation', 'Vul je opleiding in')}</i>
                                      </h5>
                                    ) : (
                                      <h5>
                                        {t('onboarding.education', 'Opleiding')}:{' '}
                                        {displayValueFromSelect(educationOptions, onboardingProfile.personalia.highestDegreeOfEducation, false)}
                                      </h5>
                                    )}
                                    {careSectorLabel === 'GHZ' && (
                                      
                                        _.isEmpty(onboardingProfile.personalia.certificates) ? (
                                          <h5>
                                            <i>{t('onboarding.enterCertificates', 'Geef aan welke aanvullende certificaten je hebt')}</i>
                                          </h5>
                                        ) : (
                                          <h5>
                                            {t('onboarding.certificates', 'Certificaten')}:{' '}
                                            {displayValueFromSelect(certificateOptions, selectedCertificates, true)}
                                          </h5>
                                        )
                                      
                                    )}
                                  </>
                                  )}
                              </>                                    
                            )}
                      </Card>                               
                          <Card data-status-filled={onboardingProfile.personalia.hourlyRate > 0}>
                            <h4>
                              {t('onboarding.hourlyRateHeading', 'Uurtarief')}
                              <span
                                onClick={(event) =>
                                  event.currentTarget === event.target && setUurtariefVisible(!uurtariefVisible)
                                }
                              >
                                {!uurtariefVisible
                                  ? t('onboarding.change', 'wijzig')
                                  : t('onboarding.close', 'sluiten')}
                              </span>
                            </h4>

                            {uurtariefVisible ? (
                              <>
                                <InputCurrency
                                  value={onboardingProfile.personalia.hourlyRate}
                                  onChange={changeOnboardingProfileInfo('hourlyRate', true)}
                                  min={0}
                                  step={0.05}
                                  placeholder={t('onboarding.inCurrency', "In euro's")}
                                />

                                <Button disabled={!(onboardingProfile.personalia.hourlyRate > 0)} onClick={saveChanges}>
                                  {t('onboarding.save', 'opslaan')}
                                </Button>
                              </>
                            ) : (
                              <h5>
                                {formatCurrency(onboardingProfile.personalia.hourlyRate)}{' '}
                                {t('onboarding.hourlyRate', 'per uur')}
                              </h5>
                            )}
                          </Card>

                          <Card data-status-filled={onboardingProfile.personalia.workingDistanceRange > 0}>
                            <h4>
                              {t('onboarding.workingDistance', 'Werkafstand')}
                              <Information>
                                {t('onboarding.howFarTravel', 'Hoe ver wil je reizen voor je werk?')}
                              </Information>
                              <span
                                onClick={(event) =>
                                  event.currentTarget === event.target && setWerkafstandVisible(!werkafstandVisible)
                                }
                              >
                                {!werkafstandVisible
                                  ? t('onboarding.change', 'wijzig')
                                  : t('onboarding.close', 'sluiten')}
                              </span>
                            </h4>
                            {werkafstandVisible ? (
                              <>
                                <InputNumber
                                  value={onboardingProfile.personalia.workingDistanceRange}
                                  onChange={(value) =>
                                    changeOnboardingProfileInfo(
                                      'workingDistanceRange',
                                      true,
                                      isFinite(value) ? Math.min(Math.max(0, value), 250) || '' : ''
                                    )
                                  }
                                  min={0}
                                  max={250}
                                  step={1}
                                  placeholder={t('onboarding.distancePlaceholder', 'In kilometers')}
                                />

                                <Button
                                  disabled={!(onboardingProfile.personalia.workingDistanceRange > 0)}
                                  onClick={saveChanges}
                                >
                                  {t('onboarding.save', 'opslaan')}
                                </Button>
                              </>
                            ) : (
                              <h5>{onboardingProfile.personalia.workingDistanceRange} km</h5>
                            )}
                          </Card>

                          <Card data-status-filled={Boolean(onboardingProfile.personalia.profilePictureUrl)}>
                            <h4>
                              {t('onboarding.profilePhoto', 'Profielfoto')}
                              <span
                                onClick={(event) =>
                                  event.currentTarget === event.target &&
                                  setProfilePictureUrlVisible(!profilePictureUrlVisible)
                                }
                              >
                                {!profilePictureUrlVisible
                                  ? t('onboarding.change', 'wijzig')
                                  : t('onboarding.close', 'sluiten')}
                              </span>
                            </h4>
                            {profilePictureUrlVisible ? (
                              <>
                                <InputFileProfilePicture
                                  value={onboardingProfile.personalia.profilePictureUrl}
                                  onChange={changeOnboardingProfileInfo('newProfilePictureBase64String', false)}
                                />
                                <Button onClick={saveChanges}>{t('onboarding.save', 'opslaan')}</Button>
                              </>
                            ) : (
                              <Avatar
                                large
                                src={onboardingProfile.personalia.profilePictureUrl}
                                alt={t('onboarding.profile', 'Profile')}
                              />
                            )}
                          </Card>

                          <Card data-status-filled={isPersonaliaComplete(onboardingProfile.personalia)}>
                            <h4>
                              {t('onboarding.personalData', 'Persoonlijke gegevens')}
                              {!addressComplete ? (
                                <Information className={styles.errorInfo} error>
                                  {t(
                                    'onboarding.addressIncomplete',
                                    'Sommige adresgegevens ontbreken, zorg ervoor dat u uw volledige adres invult.'
                                  )}
                                </Information>
                              ) : !locationValid ? (
                                <Information className={styles.errorInfo} error>
                                  {t(
                                    'onboarding.locationInvalid',
                                    'We konden geen kaartlocatie vinden voor je adres, weet je zeker dat je adres correct is ingevuld?'
                                  )}
                                </Information>
                              ) : null}

                              <span
                                onClick={(event) =>
                                  event.currentTarget === event.target && setPersonalInfoVisible(!personalInfoVisible)
                                }
                              >
                                {!personalInfoVisible
                                  ? t('onboarding.change', 'wijzig')
                                  : t('onboarding.close', 'sluiten')}
                              </span>
                            </h4>
                            {personalInfoVisible ? (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '45%',
                                    }}
                                  >
                                    <label>Voornaam</label>
                                    <InputText
                                      value={onboardingProfile.personalia.firstName}
                                      onChange={changeOnboardingProfileInfo('firstName', true)}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '45%',
                                    }}
                                  >
                                    <label>{t('onboarding.lastName', 'Achternaam')}</label>
                                    <InputText
                                      value={onboardingProfile.personalia.lastName}
                                      onChange={changeOnboardingProfileInfo('lastName', true)}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '55%',
                                    }}
                                  >
                                    <label>{t('onboarding.streetName', 'Straatnaam')}</label>
                                    <InputText
                                      value={onboardingProfile.personalia.street}
                                      onChange={changeOnboardingProfileInfo('street', true)}
                                      placeholder={t('onboarding.exampleStreetNamePlaceholder', 'Bijv. Karelstraat')}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '35%',
                                    }}
                                  >
                                    <label>{t('onboarding.houseNumber', 'Huisnummer')}</label>
                                    <InputText
                                      value={onboardingProfile.personalia.houseNumber}
                                      onChange={changeOnboardingProfileInfo('houseNumber', true)}
                                      placeholder={t('onboarding.exampleHouseNumber', 'Bijv. 5')}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '35%',
                                    }}
                                  >
                                    <label>{t('onboarding.postcode', 'Postcode')}</label>
                                    <InputText
                                      value={onboardingProfile.personalia.postcode}
                                      onChange={changeOnboardingProfileInfo('postcode', true)}
                                      placeholder={t('onboarding.examplePostcodePlaceholder', 'Bijv. 3500 AB')}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '55%',
                                    }}
                                  >
                                    <label>{t('onboarding.city', 'Plaats')}</label>
                                    <InputText
                                      value={onboardingProfile.personalia.city}
                                      onChange={changeOnboardingProfileInfo('city', true)}
                                      placeholder={t('onboarding.exampleCityPlaceholder', 'Bijv. Utrecht')}
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      flexDirection: 'column',
                                      width: '35%',
                                    }}
                                  >
                                    <label>{t('onboarding.country', 'Land')}</label>
                                    <SelectCountry
                                      value={onboardingProfile.personalia.countryCode}
                                      onChange={changeOnboardingProfileInfo('countryCode', true)}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <label>{t('onboarding.phoneNumber', 'Telefoonnummer')}</label>
                                  <InputPhone
                                    value={onboardingProfile.personalia.phoneNumber}
                                    onChange={changeOnboardingProfileInfo('phoneNumber', true)}
                                    onValidate={setPhoneNumberIsValid}
                                  />
                                </div>

                                <div>
                                  <label>{t('onboarding.dateOfBirth', 'Geboortedatum')}</label>
                                  <DatePicker
                                    value={onboardingProfile.personalia.dateOfBirth}
                                    onChange={changeOnboardingProfileInfo('dateOfBirth', true)}
                                    minDate={null}
                                    maxDate={Date.now()}
                                    placeholder={t('DateFormat', 'dd-mm-jjjj')}
                                  />
                                </div>

                                <Button disabled={isGeocoding} onClick={saveChanges}>
                                  {t('onboarding.save', 'opslaan')}
                                </Button>
                              </>
                            ) : (
                              <>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {!_.isEmpty(onboardingProfile.personalia.firstName) && (
                                    <h5>{onboardingProfile.personalia.firstName}</h5>
                                  )}
                                  {!_.isEmpty(onboardingProfile.personalia.lastName) && (
                                    <h5>{onboardingProfile.personalia.lastName}</h5>
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {!_.isEmpty(onboardingProfile.personalia.street) && (
                                    <h5>{onboardingProfile.personalia.street}</h5>
                                  )}
                                  {!_.isEmpty(onboardingProfile.personalia.houseNumber) && (
                                    <h5>{onboardingProfile.personalia.houseNumber}</h5>
                                  )}
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {!_.isEmpty(onboardingProfile.personalia.postcode) && (
                                    <h5>{onboardingProfile.personalia.postcode}</h5>
                                  )}
                                  {!_.isEmpty(onboardingProfile.personalia.city) && (
                                    <h5>{onboardingProfile.personalia.city}</h5>
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {!_.isEmpty(onboardingProfile.personalia.countryCode) && (
                                    <h5>
                                      {countryOptionsByLanguage[i18n.resolvedLanguage]?.find(
                                        (option) => option.value === onboardingProfile.personalia.countryCode
                                      )?.label ?? onboardingProfile.personalia.countryCode}
                                    </h5>
                                  )}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                  {!_.isEmpty(onboardingProfile.personalia.phoneNumber) && (
                                    <h5>{onboardingProfile.personalia.phoneNumber}</h5>
                                  )}
                                </div>
                                <div>
                                  {onboardingProfile.personalia.dateOfBirth && (
                                    <h5>
                                      {formatDate(onboardingProfile.personalia.dateOfBirth, {
                                        format: t('common:dateFormat'),
                                      })}
                                    </h5>
                                  )}
                                </div>
                                <div>
                                  {!isPersonaliaComplete(onboardingProfile.personalia) && (
                                    <h5>
                                      <i>
                                        {t(
                                          'onboarding.completePersonalInformation',
                                          'Vul je persoonlijke gegevens aan'
                                        )}
                                      </i>
                                    </h5>
                                  )}
                                </div>
                              </>
                            )}
                          </Card>

                          <Card data-status-filled={isCompanyInfoComplete(onboardingProfile.personalia)}>
                            <h4>
                              {t('onboarding.companyInformation', 'Jouw bedrijfsinformatie')}
                              <span
                                onClick={(event) =>
                                  event.currentTarget === event.target && setCompanyInfoVisible(!companyInfoVisible)
                                }
                              >
                                {!companyInfoVisible
                                  ? t('onboarding.change', 'wijzig')
                                  : t('onboarding.close', 'sluiten')}
                              </span>
                            </h4>
                            {companyInfoVisible ? (
                              <>
                                <div>
                                  <label>{t('onboarding.companyName', 'Bedrijfsnaam')}</label>
                                  <InputText
                                    value={onboardingProfile.personalia.companyName}
                                    onChange={changeOnboardingProfileInfo('companyName', true)}
                                    placeholder={t(
                                      'onboarding.exampleCompanyNamePlaceholder',
                                      'Bijv. Zorg voor elkaar'
                                    )}
                                  />
                                </div>

                                <div>
                                  <label>{t('onboarding.companyNumber', 'KvK-nummer')}</label>
                                  <InputNumber
                                    value={onboardingProfile.personalia.companyNumber}
                                    onChange={changeOnboardingProfileInfo('companyNumber', true)}
                                    step={1}
                                    placeholder={t('onboarding.exampleCompanyNumberPlaceholder', 'Bijv. 12345678')}
                                  />
                                </div>

                                <div>
                                  <label>{t('onboarding.accountNumber', 'Rekeningnummer')}</label>
                                  <InputIban
                                    value={onboardingProfile.personalia.iban}
                                    onValidate={setIbanIsValid}
                                    onChange={changeOnboardingProfileInfo('iban', true)}
                                    placeholder={t(
                                      'onboarding.exampleAccountNumberPlaceholder',
                                      'Bijv. NL91 ABNA 0417 1643 00'
                                    )}
                                  />
                                </div>

                                <div>
                                  <label>{t('onboarding.accountHolderName', 'Rekeninghouder naam')}</label>
                                  <InputText
                                    value={onboardingProfile.personalia.ibanOwnerName}
                                    onChange={changeOnboardingProfileInfo('ibanOwnerName', true)}
                                    placeholder={t(
                                      'onboarding.exampleAccountHolderNamePlaceholder',
                                      'Bijv. J. de Vries'
                                    )}
                                  />
                                </div>

                                <div>
                                  <label>{t('onboarding.taxNumber', 'BTW-nummer')}</label>
                                  <InputTax
                                    value={onboardingProfile.personalia.taxNumber}
                                    onChange={changeOnboardingProfileInfo('taxNumber', true)}
                                    onValidate={setTaxNumberIsValid}
                                    placeholder={t('onboarding.exampleTaxNumberPlaceholder', 'Bijv. NL001234567B01')}
                                  />
                                </div>

                                {['MBO4', 'HBO'].includes(onboardingProfile.personalia.highestDegreeOfEducation) && (
                                  <div>
                                    <label>{t('onboarding.bigNumber', 'BIG-nummer')}</label>
                                    <InputNumber
                                      value={onboardingProfile.personalia.bigNumber}
                                      onChange={changeOnboardingProfileInfo('bigNumber', true)}
                                      step={1}
                                      placeholder={t('onboarding.exampleBigNumberPlaceholder', 'Bijv. 12345678900')}
                                    />
                                  </div>
                                )}

                                <Button
                                  disabled={!taxNumberIsValid || !ibanIsValid}
                                  onClick={saveChanges}
                                  title={
                                    !taxNumberIsValid || !ibanIsValid
                                      ? t(
                                          'onboarding.completeAllAboveInformation',
                                          'Vul alle bovenstaande informatie in...'
                                        )
                                      : ''
                                  }
                                >
                                  {t('onboarding.save', 'opslaan')}
                                </Button>
                              </>
                            ) : (
                              <>
                                {_.isEmpty(onboardingProfile.personalia.companyName) ? (
                                  <h5>
                                    <i>{t('onboarding.enterCompanyName', 'Vul je bedrijfsnaam in')}</i>
                                  </h5>
                                ) : (
                                  <h5>
                                    {t('onboarding.companyName', 'Bedrijfsnaam')}:{' '}
                                    {onboardingProfile.personalia.companyName}
                                  </h5>
                                )}
                                {_.isEmpty(onboardingProfile.personalia.companyNumber) ? (
                                  <h5>
                                    <i>{t('onboarding.enterCompanyNumber', 'Vul je KvK-nummer in')}</i>
                                  </h5>
                                ) : (
                                  <h5>
                                    {t('onboarding.companyNumber', 'KvK-nummer')}:{' '}
                                    {onboardingProfile.personalia.companyNumber}
                                  </h5>
                                )}
                                {_.isEmpty(onboardingProfile.personalia.iban) ? (
                                  <h5>
                                    <i>{t('onboarding.enterAccountNumber', 'Vul je rekeningnummer in')}</i>
                                  </h5>
                                ) : (
                                  <h5>
                                    {t('onboarding.accountNumber', 'Rekeningnummer')}:{' '}
                                    {onboardingProfile.personalia.iban}
                                  </h5>
                                )}
                                {_.isEmpty(onboardingProfile.personalia.ibanOwnerName) ? (
                                  <h5>
                                    <i>
                                      {t('onboarding.enterAccountHolderName', 'Vul de naam in van de rekeninghouder')}
                                    </i>
                                  </h5>
                                ) : (
                                  <h5>
                                    {t('onboarding.accountHolderName', 'Rekeninghouder naam')}:{' '}
                                    {onboardingProfile.personalia.ibanOwnerName}
                                  </h5>
                                )}
                                {_.isEmpty(onboardingProfile.personalia.taxNumber) ? (
                                  <h5>
                                    <i>
                                      {t('onboarding.enterTaxNumber', 'Vul je BTW-nummer in (indien van toepassing)')}
                                    </i>
                                  </h5>
                                ) : (
                                  <h5>
                                    {t('onboarding.taxNumber', 'BTW-nummer')}: {onboardingProfile.personalia.taxNumber}
                                  </h5>
                                )}
                                {['MBO4', 'HBO'].includes(onboardingProfile.personalia.highestDegreeOfEducation) &&
                                  (_.isEmpty(onboardingProfile.personalia.bigNumber) ? (
                                    <h5>
                                      <i>{t('onboarding.enterBigNumber', 'Vul je BIG-nummer in')}</i>
                                    </h5>
                                  ) : (
                                    <h5>
                                      {t('onboarding.bigNumber', 'BIG-nummer')}:{' '}
                                      {onboardingProfile.personalia.bigNumber}
                                    </h5>
                                  ))}
                              </>
                            )}
                          </Card>

                          <Card data-status-filled={onboardingProfile.personalia.wtzaCompliant === true}>
                            <h4>{t('onboarding.wtza.header', 'Wtza')}</h4>
                            {/* TODO: translate? should probably use MarkDown then because react-i18next and large pieces of HTML is not a very good combo... */}
                            {/* <p>{t('onboarding.wtza.info', '...')}</p> */}
                            <p>
                              Per 1 januari 2022 is de{' '}
                              <A href="https://https://www.rijksoverheid.nl/onderwerpen/kwaliteit-van-de-zorg/wet-toetreding-zorgaanbieders-wtza">
                                Wet toetreding zorgaanbieders (Wtza)
                              </A>{' '}
                              van start gegaan. Dat betekent dat je als zelfstandig zorgverlener verplicht bent je te
                              registreren als zorgaanbieder. Heb je dat al gedaan? Mooi. Check{' '}
                              <A href="https://www.cibg.nl/lrza">hier</A> welke gegevens er van je bekend zijn en als
                              alles klopt kun je dat hieronder aanvinken. Heb je je nog niet geregistreerd? Doe dit dan
                              via <A href="https://www.toetredingzorgaanbieders.nl/">Toetreding zorgaanbieders</A>. Je
                              bent zelf verantwoordelijk voor het invullen van de juiste gegevens. Wij controleren bij
                              de screening alleen of we je registratie kunnen vinden.
                            </p>

                            <InputCheckbox
                              label={t('onboarding.wtza.checkboxLabel', 'Ik ben geregistreerd als zorgaanbieder')}
                              value={onboardingProfile.personalia.wtzaCompliant}
                              onChange={changeOnboardingProfileInfo('wtzaCompliant', true)}
                            />
                            <Button
                              disabled={!onboardingProfile.personalia.wtzaCompliant}
                              onClick={saveChanges}
                              title={
                                !onboardingProfile.personalia.wtzaCompliant
                                  ? t(
                                      'onboarding.wtza.required',
                                      'Je kunt niet doorgaan voordat je voldoet aan de Wtza...'
                                    )
                                  : ''
                              }
                            >
                              {t('onboarding.save', 'opslaan')}
                            </Button>
                          </Card>
                        </>
                      )}
                    </>
                  )}

                  {!isLoading &&
                    documentRequiredScreeningTypes.length > 0 &&
                    onboardingProfile.personalia.careSectorId && (
                      <section style={{ margin: '4rem 0 2rem 0' }}>
                        <h4>{t('onboarding.yourDocuments', 'Jouw documenten')}</h4>
                        <p>
                        {/* TODO Make proper translation <Trans t={t} i18nKey="onboarding.addDocuments"> */}
                            Voeg je documenten toe (klik <A href={t('onboarding.formHelpLink')}>hier</A> voor meer
                            informatie). Als je meerdere documenten wilt samenvoegen, bijv. verschillende certificaten,
                            dan kun je dit eenvoudig doen met{' '}
                            <A href="https://www.pdfen.com/nl/samenvoegen/bestanden-samenvoegen-naar-pdf">
                              dit hulpmiddel
                            </A>
                            .
                            {/* </Trans> */}
                          </p>
                      </section>
                    )}

                  {!isLoading &&
                    documentRequiredScreeningTypes.length > 0 &&
                    onboardingProfile.personalia.careSectorId &&
                    documentRequiredScreeningTypes.map((requiredDoc) => {
                      const uploadedDocument =
                        documents && documents.find((doc) => doc.screeningDocType === requiredDoc);

                      if (uploadedDocument) {
                        return (
                          <Card
                            key={uploadedDocument.id}
                            data-status-filled={true}
                            data-doc-status={uploadedDocument.status}
                          >
                            <ShowDocument
                              deleteScreeningDocument={deleteScreeningDocument}
                              uploadedDocument={uploadedDocument}
                            />
                          </Card>
                        );
                      } else {
                        return (
                          <Card key={requiredDoc} data-status-filled={false}>
                            <UploadDocument
                              id={requiredDoc}
                              displayName={requiredDoc}
                              getScreeningInformationHandler={getScreeningInformation}
                              maxSize={10000000}
                            />
                          </Card>
                        );
                      }
                    })}
                </div>
              </div>
            </>
          )}

          {/* DEBUGGING INFO */}
          {false && (
            <ul>
              <li>readyForScreening: {readyForScreening ? 1 : 0}</li>
              <li>screeningCompleted: {onboardingResult.screeningCompleted ? 1 : 0}</li>
              <li>showWelcomeScreen: {showWelcomeScreen ? 1 : 0}</li>
              <li>progress: {progressStatus}</li>
            </ul>
          )}
        </>
      ) : (
        <div>{t('onboarding.onboardingNotYetLoaded', 'Onboarding nog niet geladen...')}</div>
      )}
    </div>
  );
};

export default Onboarding;

import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useGateway } from 'src/providers/gateway';
import Dialog from '../dialog/dialog';
import Select from '../select/select';
import { createSelectOptions } from 'src/hooks/helpers';

const ChangeWorkProfileDialog = ({ profile, onConfirm, onCancel }) => {
  const { t } = useTranslation('components');
  const { api } = useGateway();

  const [selectedCareSector, setSelectedCareSector] = useState(profile.personalia.careSectorId);
  const [selectedEducation, setSelectedEducation] = useState(profile.personalia.highestDegreeOfEducation);
  const [selectedCertificates, setSelectedCertificates] = useState(profile.personalia.certificates ? profile.personalia.certificates.replaceAll(', ', ',').split(',') : []);

  const confirm = async () => {
    try {
      await api.onboardingScreenings.saveCaregiversWorkProfile({
        caregiverId: profile.personalia.caregiverId,
        careSectorId: selectedCareSector,
        education: selectedEducation,
        certificates: selectedCertificates.length>0 ? selectedCertificates.reduce((result,item) =>`${result},${item}`) : '',
      });
      onConfirm();
    } catch (err) {
      console.error('Error updating education:', err);
    }
  };

  const careSectorOptions = useMemo(() => 
    createSelectOptions(profile.careSectors),
    [profile]
   )

  const educationOptions = useMemo(() => {
            
    const careSectorLabel = selectedCareSector!==undefined ? profile.careSectors[selectedCareSector] : '';
    // _.find(careSectorOptions, { value: careSectorId })?.label;
    
      return createSelectOptions(careSectorLabel!=='' ? profile.degrees[careSectorLabel.toLowerCase()] : {}); 
    },
    [selectedCareSector, profile]
  )

  const certificateOptions = useMemo(() =>
    profile.certificates ? Object.entries(profile.certificates).map(([value, label]) => ({ value, label })) : [],
    [profile]
  ) 

  const cancel = () => {
    onCancel();
  };

  return (
    <Dialog
      onConfirm={confirm}
      onCancel={cancel}
      invertAction
      labelQuestion={
        <Trans t={t} i18nKey="changeProfileInfo">
          Je gaat informatie van deze persoon aanpassen.
          <br />
          Dit kan tot gevolg hebben dat sommige documenten overbodig zijn, of er nieuwe documenten aangeleverd moeten
          worden.
          <br />
          Als er nieuwe documenten vereist zijn, komt deze screening weer in de lijst ‘Te Keuren Profielen’.
        </Trans>
      }
      labelCancel={t('cancel', 'Annuleren')}
      labelConfirm={t('change', 'Veranderen')}
    >
      <Select        
        onChange={setSelectedCareSector}
        options={careSectorOptions}
        value={selectedCareSector}
        placeholder={t('chooseSector', 'Kies een sector')}
        />

      <Select        
        onChange={setSelectedEducation}
        options={educationOptions}
        value={selectedEducation}
        placeholder={t('chooseEducationCourse', 'Kies een opleiding')}
      />
      <Select        
        onChange={setSelectedCertificates}
        options={certificateOptions}
        value={selectedCertificates}
        multiple = {true}
        placeholder={t('chooseCertificates', 'Kies certificaten')}
        />
    </Dialog>
  );
};

export default ChangeWorkProfileDialog;

import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Eye } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import A from 'src/components/a';
import AddressView from 'src/components/address/view';
import Button from 'src/components/button/button';
import Error from 'src/components/error/error';
import { Form, FormActions, FormRow, FormSpacer } from 'src/components/form/form';
import { LabeledFormInputView } from 'src/components/form/labeled_form_input';
import LoadingOverlay from 'src/components/loader/loader';
import useCommonData from 'src/hooks/useCommonData';
import { useUserInformation } from 'src/hooks/users';
import { displayValueFromSelect, formatDateTime } from 'src/utils';
import ActionsMenu from './actionsMenu';
import { getPostingDocumentTypeLabel, PostingStatus } from './constants';
import styles from './postings.module.scss';
import ReactionForm from './reactions/form';
import { createSelectOptions } from 'src/hooks/helpers';

const PostingView = ({ posting = {}, onClose }) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const navigate = useNavigate();
  const { hasRole, data: user } = useUserInformation();
  const {
    data: { careSectors, degrees, certificates: certificateOptions, teams },
  } = useCommonData();
  const showAgreedRate = hasRole('Admin') || hasRole('Recruiter');

  // Show the agreed rate if it is set and either the user is the selected caregiver for the posting or Recruiter / Admin.
  const isAgreedRateVisible = posting?.agreedRate && (user.id === posting?.caregiverId || showAgreedRate);

  const [showReactionForm, setShowReactionForm] = useState(false);
  const [degreeOptions, setDegreeOptions] = useState([]);
  const [careSectorLabel, setCareSectorLabel] = useState('');
  const reactionFormRef = useRef(null);

  // User can react if they are caregiver and haven't reacted already
  const canReact = hasRole('Caregiver') && !_.find(posting.reactions, { caregiver: { id: user.id } });

  useEffect(() => {
    const sectorLabel =
      _.find(careSectors, { value: posting.careSectorId })?.label;
    setCareSectorLabel(sectorLabel);
    setDegreeOptions(createSelectOptions(sectorLabel ? degrees[sectorLabel.toLowerCase()] : {})); 

  }, [posting.careSectorId, careSectors, degrees], );



  const closeReactionForm = (isSaved) => {
    setShowReactionForm(false);
    if (isSaved) {
      // refresh page
      navigate(0);
    }
  };

  useEffect(() => {
    if (showReactionForm) {
      reactionFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showReactionForm]);

  const bindModel = (label, key, props = {}) => (
    <LabeledFormInputView label={label} value={_.defaultTo(_.get(posting, key), '-')} {...props} />
  );

  return (
    <Form>
      <FormRow>
        <LabeledFormInputView
          label={t('view.startDate', 'Startdatum')}
          value={formatDateTime(posting.startDate, { format: t('common:dateFormat') })}
        />
        <LabeledFormInputView
          label={t('view.endDate', 'Einddatum')}
          value={formatDateTime(posting.endDate, { format: t('common:dateFormat') })}
        />
        <LabeledFormInputView label="Status" value={t(`constants.postingStatusLabels.${posting.status}`)} />
      </FormRow>
      <FormRow>
        {bindModel(t('view.hoursPerWeek', 'Uren per week'), 'hoursPerWeek')}
        {bindModel(t('view.suggestedRate', 'Voorstel uurtarief'), 'suggestedRate', { isCurrency: true })}
        {isAgreedRateVisible ? (
          bindModel(t('view.agreedRate', 'Definitief uurtarief'), 'agreedRate', { isCurrency: true })
        ) : (
          <FormSpacer />
        )}
      </FormRow>
      <FormRow>
        {bindModel(t('view.team', 'Team'), 'teamName')}
        {bindModel(t('view.healthCareSectorName', 'Zorgsector'), 'careSectorName')}
        {bindModel(t('view.healthCareType', 'Zorgtype'), 'careType')}
      </FormRow>
      <FormRow>
        {bindModel(t('view.education', 'Opleiding'), 'requiredDegree', { options: degreeOptions || [] })}

        { careSectorLabel==='GHZ' && (
          <LabeledFormInputView
            label={t('view.certificates', 'Certificaten')}
            value={displayValueFromSelect(certificateOptions, posting.certificates, true)}
          />
        )}

      </FormRow>
        <FormSpacer />
      <FormRow>
        <AddressView
          address={posting.address || {}}
          onChange={_.noop}
          pinTitle={_.get(
            _.find(teams, { value: posting.districtTeamId }),
            'label',
            t('view.teamLocation', 'Team-locatie')
          )}
        />
      </FormRow>

      <FormRow>{bindModel(t('view.description', 'Omschrijving'), 'description')}</FormRow>
      <FormRow>{bindModel(t('view.required', 'CV vereist'), 'resumeRequired', { isBoolean: true })}</FormRow>
      <FormRow>
        <LabeledFormInputView
          label={t('view.additionalDocuments', 'Aanvullende documenten')}
          value={
            posting.documents && posting.documents.length > 0
              ? posting.documents.map((doc) => (
                  <A key={doc.id} href={doc.url} className={styles.iconLink}>
                    <Eye />
                    {getPostingDocumentTypeLabel(doc.type, t)}
                  </A>
                ))
              : '-'
          }
        />
      </FormRow>
      <FormRow>
        <LabeledFormInputView
          label={t('view.createdAt', 'Aangemaakt op')}
          value={formatDateTime(posting.createdAt, { format: t('common:dateTimeFormat') })}
        />
        <LabeledFormInputView label={t('view.createdBy', 'Aangemaakt door')} value={posting.createdBy} />
        <LabeledFormInputView
          label={t('view.updatedOn', 'Laatst aangepast op')}
          value={formatDateTime(posting.updatedAt, { format: t('common:dateTimeFormat') })}
        />
      </FormRow>

      {showReactionForm && (
        <FormRow>
          <div ref={reactionFormRef}>
            <ReactionForm
              resumeRequired={posting.resumeRequired}
              onClose={closeReactionForm}
              reaction={{
                postingId: posting.id,
              }}
            />
          </div>
        </FormRow>
      )}

      <FormActions>
        <Button secondary onClick={onClose}>
          {t('view.back', 'Terug')}
        </Button>
        {canReact && !showReactionForm && (
          <Button onClick={() => setShowReactionForm(true)}>{t('view.reply', 'Reageer')}</Button>
        )}
      </FormActions>
    </Form>
  );
};

const Page = ({ posting: initialPosting, error }) => {
  const { t } = useTranslation(['page_postings', 'common']);
  const navigate = useNavigate();
  const { hasRole } = useUserInformation();

  const [posting, setPosting] = useState(initialPosting);

  const canEdit = hasRole('Recruiter') || hasRole('Admin');

  useEffect(() => {
    if (initialPosting) {
      setPosting(initialPosting);
    }
  }, [initialPosting]);

  const closeForm = () => navigate('/postings');

  const updateModel = (nextModel) => {
    if (nextModel.status === PostingStatus.CancelledByPlanner) {
      closeForm();
    } else {
      setPosting(nextModel);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <h1>{t('view.applicationForLongTermCommitment', 'Aanvraag langdurige inzet')}</h1>
          {posting && canEdit && <ActionsMenu posting={posting} onChangeModel={updateModel} />}
        </div>
        {error ? (
          <Error>{t('common:error.loadError')}</Error>
        ) : posting ? (
          <PostingView posting={posting} onClose={closeForm} />
        ) : (
          <LoadingOverlay />
        )}
      </div>
    </div>
  );
};

export default Page;

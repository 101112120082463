import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Edit, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { IconButton, PlusButton } from 'src/components/button/button';
import { YesNoDialog } from 'src/components/dialog/dialog';
import LoadingOverlay from 'src/components/loader/loader';
import { emptyArray, emptyObject } from 'src/constants';
import { useMyOpenDirectBookings } from 'src/hooks/bookings';
import { createSelectOptions } from 'src/hooks/helpers';
import useCommonData from 'src/hooks/useCommonData';
import { useGateway } from 'src/providers/gateway';
import {
  formatBookingTimeInfoFromStartAndDuration,
  formatCurrency,
  formatDate,
  formatDateHtml,
} from 'src/utils/format';
import { sortOptions } from 'src/utils/select';
import { BookingType } from '../planning/constants';
import DirectBookingDialog from '../planning/dialogs/directBookingDialog';
import styles from './direct.module.scss';

const DirectBookingsList = () => {
  const { t } = useTranslation(['page_direct', 'common']);
  const { api } = useGateway();

  const {
    isLoading,
    data: { bookings = emptyArray, teams = emptyArray } = emptyObject,
    refetch,
  } = useMyOpenDirectBookings();

  const {
    data: { careSectors, degrees, certificates: certificateOptions },
  } = useCommonData();

  const [booking, setBooking] = useState();
  const [processing, setProcessing] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  const teamOptions = useMemo(() => (teams ? sortOptions(createSelectOptions(teams)) : []), [teams]);

  const saveDirectBooking = async (directBooking) => {
    setProcessing(true);

    try {
      await api.directBookings.saveDirectBooking(directBooking);

      setBooking(null);
      refetch();
    } catch (err) {
      console.error('Error saving booking:', err);
    } finally {
      setProcessing(false);
    }
  };

  const revokeBooking = async (id) => {
    setProcessing(true);

    try {
      await api.bookings.revokeBooking({ id });

      setBooking(null);
      refetch();
    } catch (err) {
      console.error('Error revoking booking:', err);
    } finally {
      setProcessing(false);
    }
  };

  const onAdd = () => {
    setBooking({
      type: BookingType.Direct,
      unbillableHours: 0,
      bookingDate: formatDateHtml(),
    });
  };

  const onEdit = (booking) => {
    setBooking(booking);
  };

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const confirmRevokeBooking = (booking) =>
    showDialog(
      t('directBookingList.confirmRevokeBookingDialogQuestion', {
        defaultValue: 'Weet je zeker dat je de boeking van {{bookingTimeInfo}} wilt intrekken?',
        bookingTimeInfo: formatBookingTimeInfoFromStartAndDuration(booking.start, booking.hours),
      }),
      () => revokeBooking(booking.id)
    );

  return (
    <>
      <div className={styles.container}>
        <PlusButton small className={styles.buttonPlus} onClick={onAdd} />

        {booking && (
          <DirectBookingDialog
            booking={booking}
            showManageDocuments
            teamOptions={teamOptions}
            careSectorOptions={careSectors}
            educationOptions={degrees}
            certificateOptions={certificateOptions}
            onConfirm={saveDirectBooking}
            onCancel={() => setBooking(null)}
          />
        )}

        <ul className={styles.list}>
          <li className={styles.headerRow}>
            <div>{t('directBookingList.date', 'Datum')}</div>
            <div>{t('directBookingList.teamTypeCare', 'Team (type zorg)')}</div>
            <div>{t('directBookingList.beginEndTime', 'Begin - Eindtijd')}</div>
            <div>{t('directBookingList.sectorEducation', 'Sector / Opleiding')}</div>
            <div>{t('directBookingList.hourlyRate', 'Uurtarief')}</div>
            <div>{t('directBookingList.actions', 'Acties')}</div>
          </li>

          {bookings &&
            (bookings.length > 0
              ? bookings.map((booking) => (
                  <li key={booking.id} className={styles.requestRow}>
                    <div className={styles.date}>
                      <span>{formatDate(booking.bookingDateTime, { format: t('common:dateFormat') })}</span>
                    </div>
                    <div className={styles.team}>
                      {booking.districtTeam.name}
                      <sub>({booking.districtTeam.typeOfCare})</sub>
                    </div>
                    <div>{formatBookingTimeInfoFromStartAndDuration(booking.bookingDateTime, booking.hours)}</div>
                    <div>
                      {booking.careSector.name} / {booking.degree}
                    </div>
                    <div>
                      {formatCurrency(booking.hourlyRate)} {t('directBookingList.hourly', 'per uur')}
                    </div>
                    <div>
                      <IconButton
                        small
                        secondary
                        icon={<Edit />}
                        title={t('directBookingList.changeBooking', 'Boeking aanpassen')}
                        onClick={() => onEdit(booking)}
                      />

                      <IconButton
                        small
                        secondary
                        className={styles.deleteBtn}
                        icon={<Trash2 />}
                        title={t('directBookingList.deleteBooking', 'Verwijder boeking')}
                        onClick={() => confirmRevokeBooking(booking)}
                      />
                    </div>
                  </li>
                ))
              : !isLoading && (
                  <li className={clsx(styles.requestRow, styles.emptyRow)}>
                    <p>
                      {t(
                        'directBookingList.currentlyNoOpenDirectRequests',
                        'Momenteel zijn er geen openstaande Direct-verzoeken'
                      )}
                    </p>
                  </li>
                ))}
        </ul>
      </div>

      {dialogData && <YesNoDialog {...dialogData} />}

      {(isLoading || processing) && <LoadingOverlay />}
    </>
  );
};

export default DirectBookingsList;

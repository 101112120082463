import _ from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Navigate as CalendarNavigate } from 'src/components/calendar';
import { YesNoDialog } from 'src/components/dialog/dialog';
import FilterToolbar from 'src/components/filter/filterToolbar';
import LoadingOverlay from 'src/components/loader/loader';
import { emptyArray, emptyObject } from 'src/constants';
import { createSelectOptions } from 'src/hooks/helpers';
import { useMyPostingsForMonth } from 'src/hooks/postings';
import useCommonData from 'src/hooks/useCommonData';
import { useGateway } from 'src/providers/gateway';
import { dateLongDisplayFormat, formatDate } from 'src/utils';
import { sortOptions } from 'src/utils/select';
import { defaultCustomShiftTimes } from '../teams/constants';
import { createBookingEvent, createDraftPostingBookingEvent } from './booking';
import BookingCalendar from './bookingCalendar';
import { BookingStatus, BookingType } from './constants';
import StartEndTimeDialog from './dialogs/startEndTimeDialog';
import ViewBookingDialog from './dialogs/viewBookingDialog';
import DraftBookingList from './draftBookingList';
import styles from './index.module.scss';

const PostingPlanner = () => {
  const { t } = useTranslation(['page_planning', 'common']);
  const { api } = useGateway();
  const {
    data: { careSectors, degrees, certificates: certificateOptions /*, teams*/ },
  } = useCommonData();

  const navigate = useNavigate();
  const { year, month } = useParams();
  const location = useLocation();

  const today = moment().startOf('day').toDate();
  const date = new Date(year, month - 1, 1);
  const onNavigate = (date, _view, action) =>
    navigate(`../${date.getFullYear()}/${date.getMonth() + 1}`, {
      state: { refetch: action === CalendarNavigate.REFRESH },
    });

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [processing, setProcessing] = useState(false);

  const [customShiftTimes, setCustomShiftTimes] = useState(defaultCustomShiftTimes);

  const [selectedEvent, setSelectedEvent] = useState();

  // The next lines are commented out because we have temporarily switched off posting booking edit support (see render output below)
  // const canEditPostingBooking = useMemo(() => {
  //   if (selectedEvent) {
  //     const {
  //       booking: { type, status, start },
  //     } = selectedEvent;
  //     return type === BookingType.Posting && status === BookingStatus.Created && moment(start).isAfter(moment());
  //   }
  //   return false;
  // }, [selectedEvent]);

  const canRevokePostingBooking = useMemo(() => {
    if (selectedEvent) {
      const {
        booking: { type, status, start },
      } = selectedEvent;
      return (
        type === BookingType.Posting &&
        [BookingStatus.Created, BookingStatus.Accepted].includes(status) &&
        moment(start).isAfter(moment())
      );
    }
    return false;
  }, [selectedEvent]);

  const {
    isLoading: postingsLoading,
    isFetched: postingsLoaded,
    data: { postings = emptyArray, teams = emptyArray, caregivers = emptyArray, bookings = emptyArray } = emptyObject,
    refetch: refetchPostings,
  } = useMyPostingsForMonth(date);

  useEffect(() => {
    const { refetch: shouldRefetch, ...state } = location.state ?? {};

    if (shouldRefetch) {
      refetchPostings();

      navigate(`${location.pathname}${location.search}${location.hash}`, {
        state,
        replace: true,
      });
    }
  }, [location, navigate, refetchPostings]);

  const bookingEvents = useMemo(
    () => (postingsLoaded ? bookings.map(createBookingEvent) : emptyArray),
    [postingsLoaded, bookings]
  );

  const [postingOptions, setPostingOptions] = useState([]);
  const [visiblePostingId, setVisiblePostingId] = useState([]);
  const [showBookingsWithoutPosting] = useState(false);

  // Create options for posting
  useEffect(() => {
    const createPostingOptions = ({ postings, teams, caregivers }) => {
      return sortOptions(
        postings.map((posting) => {
          const team = _.find(teams, { id: posting.districtTeamId });
          const caregiver = _.find(caregivers, { id: posting.caregiverId });
          const caregiverName = caregiver ? `${caregiver.firstName} ${caregiver.lastName}` : '?';

          return {
            value: posting.id,
            // regular label (displayed as option)
            label: (
              <Trans t={t} i18nKey="postingPlanner.label">
                <div>
                  {{ name: team.name }} ({{ location: team.location }}) | van{' '}
                  <span>{{ startDate: formatDate(posting.startDate, { format: dateLongDisplayFormat }) }}</span> t/m{' '}
                  <span>{{ endDate: formatDate(posting.endDate, { format: dateLongDisplayFormat }) }}</span> voor{' '}
                  <span>{{ hoursPerWeek: posting.hoursPerWeek }}</span>{' '}
                  {{ hours: t('common:hour', { count: posting.hoursPerWeek }) }} per week | {{ caregiverName }}
                </div>
              </Trans>
            ),
            // flat label (displayed as selected value)
            flatLabel: t('postingPlanner.flatLabel', {
              name: team.name,
              location: team.location,
              startDate: formatDate(posting.startDate, { format: dateLongDisplayFormat }),
              endDate: formatDate(posting.endDate, { format: dateLongDisplayFormat }),
              hoursPerWeek: posting.hoursPerWeek,
              caregiverName,
              hours: t('common:hour', { count: posting.hoursPerWeek }),
              defaultValue:
                '{{name}} ({{location}}) | van {{startDate}} t/m {{endDate}} voor {{hoursPerWeek}} {{hours}} per week | {{caregiverName}}',
            }),
          };
        }),
        (option) => option.flatLabel
      );
    };
    const options = createPostingOptions({ postings, teams, caregivers });

    setPostingOptions((prevPostingOptions) => {
      // Prevent selected items from disappearing from the list by adding them as additional options if needed
      const additionalOptions = prevPostingOptions.filter(
        (opt) => opt.value === visiblePostingId && !options.find((nextOpt) => nextOpt.value === opt.value)
      );

      return sortOptions(options.concat(additionalOptions));
    });
  }, [bookings, caregivers, postings, postingsLoaded, teams, visiblePostingId, t]);

  const teamOptions = useMemo(
    () => (postingsLoaded ? sortOptions(createSelectOptions(teams)) : emptyArray),
    [postingsLoaded, teams]
  );

  const posting = visiblePostingId ? _.find(postings, { id: visiblePostingId }) : null;
  const caregiver = posting ? _.find(caregivers, { id: posting.caregiverId }) : null;
  const team = posting ? _.find(teams, { id: posting.districtTeamId }) : null;

  const filters = [
    {
      key: 'postingFilter',
      placeholder: t('postingPlanner.selectLongTermCommitment', 'Selecteer langdurige inzet...'),
      options: postingOptions,
      value: visiblePostingId,
      onChange: setVisiblePostingId,
    },
  ];

  const filteredEvents = useMemo(() => {
    let filtered = bookingEvents;

    if (!_.isEmpty(visiblePostingId)) {
      filtered = filtered.filter((event) =>
        event.booking.postingId
          ? posting &&
            (event.booking.postingId === visiblePostingId || event.booking.caregiverId === posting.caregiverId)
          : showBookingsWithoutPosting
      );
    }

    return filtered;
  }, [bookingEvents, posting, showBookingsWithoutPosting, visiblePostingId]);

  const [draftEvents, setDraftEvents] = useState([]);
  const [draftEvent, setDraftEvent] = useState();

  // Only allow creating events in the future.
  // NB: we could in theory allow adding events in the past for correctional bookings.
  const canAddEvent = (date) => date >= today && posting;

  const onSelectSlot = ({ slots: _slots, start, end }) => {
    if (canAddEvent(start)) {
      // Replace draft event with a new one
      const draftEvent = createDraftPostingBookingEvent({ start, end, posting, caregiver, team });

      setDraftEvent(draftEvent);
    }
  };

  const onSelectEvent = (event) => {
    if (draftEvents.includes(event)) {
      setDraftEvent(event);
    } else {
      setSelectedEvent(event);
    }
  };

  const onConfirmDraftEvent = (event) => {
    setDraftEvents(_.reject(draftEvents, ['meta.draftId', event.meta.draftId]).concat(event));
    setDraftEvent();
  };

  const removeDraftEvent = (draftId) => {
    setDraftEvents(_.reject(draftEvents, ['meta.draftId', draftId]));
  };

  const savePostingBooking = async (event) => {
    setProcessing(true);
    try {
      await api.bookings.savePostingBooking({ postingBooking: event.booking });

      setShowEditDialog(false);
      setSelectedEvent();
      refetchPostings();
    } catch (err) {
      console.error('Error saving booking:', err);

      return false;
    } finally {
      setProcessing(false);
    }
  };

  // Save a batch of posting bookings
  const savePostingBookings = async () => {
    setProcessing(true);
    try {
      await api.bookings.savePostingBookings({
        postingBookings: draftEvents.map((event) => ({
          postingId: event.booking.postingId,
          bookingId: null, // new -> null,
          startDate: moment(event.start).toISOString(true),
          endDate: moment(event.end).toISOString(true),
          unbillableHours: event.booking.unbillableHours || 0,
        })),
      });

      setDraftEvents([]);
      refetchPostings();
    } catch (err) {
      alert(t('postingPlanner.errorMessage', 'Er trad een fout op!'));
      console.error('Error saving bookings:', err);
    } finally {
      setProcessing(false);
    }
  };

  const revokePostingBooking = async (id) => {
    try {
      await api.bookings.revokeBooking({ id });

      setSelectedEvent();
      refetchPostings();
    } catch (err) {
      console.error('Error revoking booking:', err);
    }
  };

  const showDialog = (labelQuestion, onConfirm) =>
    setDialogData({
      labelQuestion,
      onConfirm,
      onCancel: () => setDialogData(),
    });

  const confirmSavePostingBookings = () =>
    showDialog(
      t(
        'postingPlanner.conformSavePostingBookingsDialogQuestion',
        'Een extra check kan nooit kwaad. Wil je deze boekingen bevestigen?'
      ),
      savePostingBookings
    );
  const confirmRemoveDraftBooking = (draftId) =>
    showDialog(
      t(
        'postingPlanner.confirmRemoveDraftBookingDialogQuestion',
        'Weet je zeker dat je deze boeking wilt verwijderen?'
      ),
      () => removeDraftEvent(draftId)
    );

  return (
    <>
      {dialogData && <YesNoDialog {...dialogData} />}

      <div className={styles.container}>
        <BookingCalendar
          date={date}
          onNavigate={onNavigate}
          onSelectSlot={onSelectSlot}
          onSelectEvent={onSelectEvent}
          events={filteredEvents}
          draftEvents={draftEvents}
          draftEventsListComponent={(props) => (
            <DraftBookingList
              onDeleteItem={confirmRemoveDraftBooking}
              onProceed={confirmSavePostingBookings}
              {...props}
            />
          )}
          draftEvent={draftEvent}
          toolbars={[
            <FilterToolbar
              key="filters"
              filters={filters}
              label={t(
                'postingPlanner.chooseApplicationToStartPlanning',
                'Kies een aanvraag om te beginnen met plannen'
              )}
            />,
          ]}
        />

        {draftEvent && (
          <StartEndTimeDialog
            // filled in data
            draftEvent={draftEvent}
            // custom shift data
            customShift={customShiftTimes}
            onChangeCustomShift={setCustomShiftTimes}
            // event handlers
            onConfirm={onConfirmDraftEvent}
            onCancel={() => setDraftEvent()}
          />
        )}

        {selectedEvent && (
          <ViewBookingDialog
            bookingEvent={selectedEvent}
            revokeBooking={canRevokePostingBooking ? revokePostingBooking : null}
            teamOptions={teamOptions}
            careSectorOptions={careSectors}
            educationOptions={degrees}
            certificateOptions={certificateOptions}
            // Edit disabled, because of D/T issues. Earlier (before 03-04-2023) it was broken in a different way already:
            // shown time values were off by 2 hours even before subtracting 2 more hours due to D/T workarounds
            // onEdit={canEditPostingBooking ? () => setShowEditDialog(true) : null}
            onClose={() => setSelectedEvent()}
          />
        )}

        {showEditDialog && (
          <StartEndTimeDialog
            // filled in data
            postingBookingEvent={selectedEvent}
            // event handlers
            onConfirm={savePostingBooking}
            onCancel={() => setShowEditDialog(false)}
          />
        )}

        {(postingsLoading || processing) && <LoadingOverlay />}
      </div>
    </>
  );
};

const PostingPlannerRoutes = () => {
  const now = new Date();

  return (
    <Routes>
      <Route path=":year/:month/" element={<PostingPlanner />} />
      <Route path="*" element={<Navigate to={`${now.getFullYear()}/${now.getMonth() + 1}`} />} />
    </Routes>
  );
};

export default PostingPlannerRoutes;

import clsx from 'clsx';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import Card from 'src/components/card/card';
import DatePicker from 'src/components/datepicker/datepicker';
import { YesNoDialog } from 'src/components/dialog/dialog';
import Error from 'src/components/error/error';
import Checkbox from 'src/components/input_checkbox/input_checkbox';
import { InputTime } from 'src/components/input_datetime';
import { InputCurrency } from 'src/components/input_number';
import InputNumber from 'src/components/input_number/input_number';
import InputSearch from 'src/components/input_search/input_search';
import LoadingOverlay from 'src/components/loader/loader';
import Select from 'src/components/select/select';
import useCommonData from 'src/hooks/useCommonData';
import { useGateway } from 'src/providers/gateway';
import { formatDate, formatDateHtml, formatTime } from 'src/utils';
import styles from './correction_booking.module.scss';

const emptyCorrectionBooking = {
  beginTime: '',
  bookingDate: '',
  caregiverId: '',
  districtTeamId: '',
  careSectorId: '',
  degree: 'None',
  endTime: '',
  hourlyRate: 0.0,
};

const CorrectionBookingForm = () => {
  const { t } = useTranslation(['page_admin', 'common']);
  const { api } = useGateway();
  const {
    data: { teams, careSectors },
    isLoading: commonDataLoading,
  } = useCommonData();

  const [caregiver, setCaregiver] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [dialogSaveCorrectionBooking, setDialogSaveCorrectionBooking] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasUnpaidBreak, setHasUnpaidBreak] = useState(false);
  const [unbillableMinutes, setUnbillableMinutes] = useState(0);
  const [isChangedManual, setIsChangedManual] = useState(false);

  const [correctionBooking, setCorrectionBooking] = useState({
    ...emptyCorrectionBooking,
    bookingDate: formatDateHtml(),
  });

  const [error, setError] = useState();

  const [searchValue, setSearchValue] = useState('');
  const caregiverOptions = useMemo(() => {
    return caregivers?.map((caregiver) => {
      const flatLabel = `${caregiver.firstName} ${caregiver.lastName}`;

      const hasKnownExpirationDate = Boolean(caregiver.expirationDate);
      const isExpiredToday = hasKnownExpirationDate && moment().isAfter(caregiver.expirationDate, 'day');
      const isExpiredAtBookingDate =
        hasKnownExpirationDate && moment(correctionBooking.bookingDate).isAfter(caregiver.expirationDate, 'day');

      return {
        value: caregiver.id,
        label: (
          <div className={styles.caregiverLabel}>
            <div>{flatLabel}</div>
            {!caregiver.isActive && <div className={clsx(styles.tag, styles.inactive)}>{t('common:tag.inactive')}</div>}
            {caregiver.isBlocked && <div className={clsx(styles.tag, styles.blocked)}>{t('common:tag.blocked')}</div>}
            {isExpiredToday ? (
              <div className={clsx(styles.tag, styles.expiryError)}>
                {t('common:tag.expiredAt', { date: formatDate(caregiver.expirationDate) })}
              </div>
            ) : isExpiredAtBookingDate ? (
              <div className={clsx(styles.tag, styles.expiryWarning)}>
                {t('common:tag.expiresAt', { date: formatDate(caregiver.expirationDate) })}
              </div>
            ) : null}
          </div>
        ),
        flatLabel,
        profilePictureUrl: caregiver.profilePictureUrl,
      };
    });
  }, [caregivers, correctionBooking.bookingDate, t]);

  const selectTeam = (teamId) => {
    const team = teams.find((t) => t.value === teamId).data;

    setCaregivers([]);

    setCorrectionBooking((prevState) => ({
      ...prevState,
      districtTeamId: teamId,
      careSectorId: team.careSectorId,
    }));
  };

  const selectCaregiver = (caregiverId) => {
    const caregiver = caregivers.find((caregiver) => caregiver.id === caregiverId);
    setCaregiver(caregiver);
    // const hourlyRate = correctionBooking.hourlyRate > 0 ? correctionBooking.hourlyRate : caregiver.hourlyRate;
    const hourlyRate = caregiver.hourlyRate;
    setCorrectionBooking((prevState) => ({
      ...prevState,
      caregiverId: caregiverId,
      hourlyRate: hourlyRate,
    }));
    setIsChangedManual(false);
  };

  const getCaregiversByName = async () => {
    setIsLoading(true);

    try {
      const data = await api.caregivers.getCaregiversByName({
        searchName: searchValue,
        districtTeamId: correctionBooking.districtTeamId,
        includeBlocked: true,
        includeInactive: true,
      });

      setCaregivers(data.caregivers);
    } catch (err) {
      console.error('Error retrieving caregivers:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveCorrectionalBooking = async () => {
    setIsLoading(true);
    try {
      const unbillableHours = hasUnpaidBreak && unbillableMinutes > 0 ? unbillableMinutes / 60 : 0;
      await api.bookings.saveCorrectionalBooking({
        ...correctionBooking,
        unbillableHours,
      });

      setCaregiver([]);
      setCaregivers([]);
      setSearchValue('');

      setCorrectionBooking({
        ...emptyCorrectionBooking,
        bookingDate: formatDateHtml(),
      });

      setUnbillableMinutes(0);
      setHasUnpaidBreak(false);
    } catch (err) {
      console.error('Error saving booking:', err);

      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const now = moment();
  const minDate = moment(now).subtract(6, 'month').startOf('day');

  const isValid =
    useMemo(() => Object.values(correctionBooking).every((value) => value !== ''), [correctionBooking]) &&
    moment(correctionBooking.bookingDate) >= minDate;

  return (
    <div className={styles.container}>
      {isLoading && <LoadingOverlay />}
      {error ? (
        <Error onDismiss={() => setError()}>
          <div>{t('common:error.saveError')}</div>
          <div>{error}</div>
        </Error>
      ) : (
        !commonDataLoading && (
          <>
            <h1 style={{ textAlign: 'center' }}>
              {t('correctionBooking.headingSubmitCorrectionBooking', 'Correctieboeking indienen')}
            </h1>
            <Card>
              {teams && (
                <div>
                  <label>{t('correctionBooking.team', 'Team')}</label>
                  <Select
                    onChange={selectTeam}
                    options={teams}
                    value={correctionBooking.districtTeamId}
                    placeholder={t('correctionBooking.chooseTeam', 'Kies een team')}
                  />
                </div>
              )}

              {careSectors && (
                <div>
                  <label>{t('correctionBooking.sector', 'Sector')}</label>
                  <Select
                    onChange={(value) => {
                      setCorrectionBooking((prevState) => ({
                        ...prevState,
                        careSectorId: value,
                      }));
                    }}
                    options={careSectors}
                    value={correctionBooking.careSectorId}
                    placeholder={t('correctionBooking.chooseHealthCareSector', 'Kies een zorgsector')}
                  />
                </div>
              )}

              <div>
                <div>
                  <label>{t('correctionBooking.datum', 'Datum')}</label>
                </div>
                <DatePicker
                  placeholder={t('common:dateFormat')}
                  minDate={minDate}
                  maxDate={now}
                  value={moment(correctionBooking.bookingDate)}
                  onChange={(value) => {
                    setCorrectionBooking((prevState) => ({
                      ...prevState,
                      bookingDate: value,
                    }));
                  }}
                />
              </div>

              <div className={styles.times}>
                <label>{t('correctionBooking.startEndTime', 'Begintijd - Eindtijd')}</label>
                <div>
                  <InputTime
                    placeholder={t('correctionBooking.sampleTimePlaceholderStart', 'Bijv. 13:00')}
                    value={correctionBooking.beginTime}
                    onChange={(value) => {
                      setCorrectionBooking((prevState) => ({ ...prevState, beginTime: value }));
                    }}
                  />
                  <span className={styles.sep}>-</span>
                  <InputTime
                    placeholder={t('correctionBooking.sampleTimePlaceholderEnd', 'Bijv. 17:00')}
                    value={correctionBooking.endTime}
                    onChange={(value) => {
                      setCorrectionBooking((prevState) => ({ ...prevState, endTime: value }));
                    }}
                  />
                </div>
              </div>

              <div className="align-horizontal">
                <Checkbox
                  value={hasUnpaidBreak}
                  label={t('correctionBooking.includingUnpaidBreak', 'Inclusief onbetaalde pauze (in minuten)')}
                  onChange={setHasUnpaidBreak}
                ></Checkbox>
                {hasUnpaidBreak && (
                  <InputNumber onChange={setUnbillableMinutes} value={unbillableMinutes} min={0} step={1} />
                )}
              </div>

              <div>
                <label>{t('correctionBooking.caregiver', 'Zorgverlener')}</label>
                <InputSearch
                  value={searchValue}
                  onChange={setSearchValue}
                  onSearch={getCaregiversByName}
                  {...(correctionBooking.districtTeamId
                    ? {
                        placeholder: t('correctionBooking.findHealthCareProvider', 'Zoek een zorgverlener...'),
                      }
                    : {
                        placeholder: t('correctionBooking.firstChooseTeam', 'Kies eerst een team'),
                        disabled: true,
                      })}
                />
                {!isLoading && caregiverOptions && caregiverOptions.length > 0 && (
                  <Select
                    onChange={selectCaregiver}
                    options={caregiverOptions}
                    value={caregiver.id}
                    placeholder={t('correctionBooking.chooseHealthCareProvider', 'Kies een zorgverlener')}
                    style={{ marginTop: '1rem' }}
                  />
                )}
              </div>
              <div>
                <label>
                  {isChangedManual
                    ? t('correctionBooking.hourlyRateChanged', 'Uurtarief aangepast')
                    : correctionBooking.caregiverId
                    ? t('correctionBooking.hourlyRateCaregiver', 'Uurtarief geselecteerde zorgverlener')
                    : t('correctionBooking.hourlyRate', 'Uurtarief')}
                </label>
                <InputCurrency
                  placeholder={t('dialogs.bookingForm.hourlyRateInCurrencyPlaceholder', 'Uurtarief in euros')}
                  value={correctionBooking.hourlyRate}
                  onChange={(value) => {
                    setCorrectionBooking((prevState) => ({
                      ...prevState,
                      hourlyRate: value,
                    }));
                    setIsChangedManual(true);
                  }}
                />
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  disabled={!isValid}
                  onClick={() => {
                    setDialogSaveCorrectionBooking(true);
                  }}
                >
                  {t('correctionBooking.addCorrectionButton', 'Correctie toevoegen')}
                </Button>
              </div>

              {dialogSaveCorrectionBooking && (
                <YesNoDialog
                  onConfirm={() => {
                    saveCorrectionalBooking();
                    setDialogSaveCorrectionBooking(false);
                  }}
                  onCancel={() => {
                    setDialogSaveCorrectionBooking(false);
                  }}
                  labelQuestion={
                    // TODO Refactor. There has to be a better way
                    hasUnpaidBreak && unbillableMinutes > 0 ? (
                      <Trans t={t} i18nKey="correctionBooking.dialogSaveCorrectionBookingQuestionWithBreak">
                        Je gaat een correctieboeking toevoegen op{' '}
                        <b>
                          {{
                            bookingDate: formatDate(correctionBooking.bookingDate, { format: t('common:dateFormat') }),
                          }}
                        </b>{' '}
                        van <b>{{ beginTime: formatTime(correctionBooking.beginTime) }}</b> tot{' '}
                        <b>{{ endTime: formatTime(correctionBooking.endTime) }}</b> met een pauze van{' '}
                        <b>{{ unbillableHours: unbillableMinutes }}</b> minuten. Het uurtarief is{' '}
                        <b>{{ hourlyRate: correctionBooking.hourlyRate }}</b> euro.
                        <br />
                        Je hebt gekozen voor{' '}
                        <b>
                          {{ firstName: caregiver.firstName }} {{ lastName: caregiver.lastName }}
                        </b>{' '}
                        <br />
                        <br />
                        Weet je het zeker?
                      </Trans>
                    ) : (
                      <Trans t={t} i18nKey="correctionBooking.dialogSaveCorrectionBookingQuestionWithoutBreak">
                        Je gaat een correctieboeking toevoegen op{' '}
                        <b>
                          {{
                            bookingDate: formatDate(correctionBooking.bookingDate, { format: t('common:dateFormat') }),
                          }}
                        </b>{' '}
                        van <b>{{ beginTime: formatTime(correctionBooking.beginTime) }}</b> tot{' '}
                        <b>{{ endTime: formatTime(correctionBooking.endTime) }}</b>. Het uurtarief is{' '}
                        <b>{{ hourlyRate: correctionBooking.hourlyRate }}</b> euro.
                        <br />
                        Je hebt gekozen voor{' '}
                        <b>
                          {{ firstName: caregiver.firstName }} {{ lastName: caregiver.lastName }}
                        </b>{' '}
                        <br />
                        <br />
                        Weet je het zeker?
                      </Trans>
                    )
                  }
                />
              )}
            </Card>
          </>
        )
      )}
    </div>
  );
};

export default CorrectionBookingForm;
